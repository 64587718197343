import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { AirportData } from '../../components/data/AirportData';

const CityDropdown = ({ selectedCity, setSelectedCity }) => {
  const [query, setQuery] = useState('');
  
  const cities = ['All Cities', ...new Set(AirportData.map(data => data.city))]; // Add "All Cities" option

  const filteredCities = query === ''
    ? cities
    : cities.filter(city =>
        city.toLowerCase().includes(query.toLowerCase())
      );

  return (
    <div className="flex flex-col justify-center items-center text-center">
      <div className="relative w-[97%]">
        <Combobox value={selectedCity} onChange={setSelectedCity}>
          <Combobox.Input
            className="text-center outline-none text-[13px] font-bold text-white bg-black w-[80px]"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(city) => city}
            placeholder="Select City"
          />
          <Combobox.Options className="mt-1 absolute z-[999] bg-gray-100 border rounded-md max-h-60 overflow-auto">
            {filteredCities.map((city, index) => (
              <Combobox.Option
                key={index}
                value={city}
                className={({ active }) => `px-4 py-2 cursor-pointer ${active ? 'bg-mobius-golden text-white' : ''}`}
              >
                {city}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox>
      </div>
    </div>
  );
};

export default CityDropdown;
