
import React, {  Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
const StatusModal =({isStatusModalOpen,toggleStatusModal,setSelectedStatus,handleStatusSubmit,selectedStatus})=>{
    return(
        <Transition appear show={isStatusModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleStatusModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Update Flight Status
                  </Dialog.Title>
                  <div className="mt-4">
                    <select
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg   focus:border-mobius-golden"
                    >
                      <option value="On Time">On Time</option>
                      <option value="On Flight">On Flight</option>
                      <option value="Delayed">Delayed</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border  bg-white border-2 border-mobius-golden px-4 py-2 text-sm font-medium text-black hover:bg-mobius-golden focus:outline focus-visible:ring-2 focus-visible:ring-mobius-golden hover:text-white focus-visible:ring-offset-2"
                      onClick={handleStatusSubmit}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={toggleStatusModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
}
export default StatusModal