export function saveSearchData(data) {
    localStorage.setItem('searchData', JSON.stringify(data));
}
export function saveReturnData(data) {
    localStorage.setItem('round', true);
    localStorage.setItem('returnData', JSON.stringify(data));
   
   
}

export function getSearchData() {

    const data = localStorage.getItem('searchData');
    return data ? JSON.parse(data) : null;
}
export function getReturnData() {
    const data = localStorage.getItem('returnData');
 
    return data ? JSON.parse(data) : null;

}

