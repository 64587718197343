import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          "Navbar": {
            "home": "Home",
            "about": "About Us",
            "trips": "My Trips",
            "contact": "Contact Us",
            "login": "Login",
            "reg": "Registration"
          },
          "Footer": {
            "intro": "Redefine first class with Mobius",
            "about": "About Us",
            "whoWeAre": "Who are We?",
            "allies": "Partners",
            "sus": "Sustainability",
            "customer": "Satisfied Customers",
            "info": "Information",
            "flightStatus": "Flight Status",
            "checkIn": "Check In",
            "booking": "Booking",
            "myFlights": "My Flights",
            "legal": "Legal",
            "privacyPolicy": "Privacy Policy",
            "cookiesPolicy": "Cookies Policy",
            "privacyNote": "Privacy Notice",
            "terms": "Terms and Conditions"
          },
          "Menu": {
            "profile": "Profile",
            "planeReg": "Register Plane",
            "flightReg": "Register Flight",
            "flightBook": "Book Flight",
            "seeFlight": "See Flights",
            "howItWork": "How This Works?",
            "logout": "LogOut"

          },
          "SearchFlight": {
            "single": "Single",
            "round": "Round",
            "origin": "Origin",
            "dest": "Destination",
            "passenger": "Passengers",
            "departure": "Departure Date",
            "return": "Return Date",
            "book": "Book Now"

          },
          "heroHome": {
            "upperText": "Elevate Your Travel Experience",
            "lowerText": "Redefine Luxury in the Skies"
          },
          "jetSection": {
            "latest": "Latest generation aircraft",
            "safest": "Fly in the most modern and safest aircraft in the world",
            "catalog": "Take a look at our aircraft catalog.",
            "seeAll": "See All"

          },
          "quality": {
            "qualityText": "Quality guaranteed",
            "cert": "Certified Personal",
            "certDesc": "Our certified and highly trained pilots combine years of experience in private aviation to provide you with a safe and reliable journey.",
            "catering": "Professional Catering",
            "cateringDesc": "Enjoy a selection of exquisite dishes designed to satisfy your most discerning tastes while traveling in comfort and style.",
            "access": "Exclusive Access",
            "accessDesc": "Avoid long lines at airports and access your flight through exclusive entrances for Mobius customers",
            "executive": "Forget about business class",
            "executiveDesc": "Instead of wasting on business class, invest on exectutive flights"


          },
          "AboutUs": {
            "intro": "At Mobius, we believe in transcending the traditional boundaries of air travel. Founded with the vision of offering unmatched luxury, convenience and security,",
            "introS": "Mobius is your main option",
            "feature1": "Plan your flight according to your schedules and destinations preferred, with the flexibility that only private aviation can offer.",
            "feature2": "Plan your flight according to your schedules and destinations preferred, with the flexibility that only private aviation can offer.",
            "joinText": "Join the Mobius Experience",
            "discoverText": "Discover the art of flying privately with Mobius. We promise you an experience as unique as you. Trust us to get you where you need to be, with elegance and ease."


          },
        }
      },

      sp:{
        translation: {
          "Navbar": {
            "home": "Inicio",
            "about": "Sobre nosotras",
            "trips": "Mis viajes",
            "contact": "Contacta ",
            "login":"Acceso",
            "reg":"Registro"
          },
          "Footer":{
              "intro":"Redefine la primera clase con Mobius",
              "about":"Nosotros",
              "whoWeAre":"¿Quienes Somos?",
              "allies":"Aliados",
              "sus":"Sustentabilidad",
              "customer":"Clientes Satisfechos",
              "info":"Información",
              "flightStatus":"Estatus de vuelo",
              "checkIn":"Registrarse",
              "booking":"Reserva",
              "myFlights":"Mis vuelos",
              "legal":"Legal",
              "privacyPolicy":"Política de Privacidad",
              "cookiesPolicy":"Política de cookies",
              "privacyNote":"Aviso de Privacidad",
              "terms":"Têrminos y Condiciones"
           },
          "Menu":{
              "profile": "Perfil",
              "planeReg":"Agregar Avión",
              "flightReg":"Agendar Vuelo",
              "flightBook":"Book Flight",
              "seeFlight":"Ver vuelos",
              "howItWork":"¿Cómo Funciona?",
              "logout":"Cerrar Sesion"
      
          },
          "SearchFlight": {
              "single": "Soltero",
              "round": "Redondo",
              "origin": "Origen",
              "dest": "Destino",
              "passenger":"Pasajeros",
              "departure":"Fecha de salida",
              "return":"Fecha de regreso",
              "book":"Reserva ahora"
      
            },
            "heroHome":{
              "upperText":"Eleva tu Experiencia de Viaje",
              "lowerText":" Redefine el Lujo en los Cielos"
            },
            "jetSection":{
              "latest":"Aeronaves de última generación",
              "safest":"Vuela en las aeronaves más modernas y seguras del mundo",
              "catalog":"Echa un vistazo a nuestro catálogo de aeronaves.",
              "seeAll":" Ver Todas"
      
            },
            "quality":{
              "qualityText":"Calidad garantizada",
              "cert":"Personal certificado",
              "certDesc":"Nuestros pilotos certificados y altamente capacitados combinan años de experiencia en aviación privada para brindarle un viaje seguro y confiable.",
              "catering":"Catering profesional",
              "cateringDesc":"Disfrute de una selección de exquisitos platos diseñados para satisfacer sus gustos más exigentes mientras viaja con comodidad y estilo.",
              "access":"Acceso Exclusivo",
              "accessDesc":"Evita largas colas en los aeropuertos y accede a tu vuelo a través de entradas exclusivas para clientes de Mobius",
              "executive":"Olvídate de la clase ejecutiva",
              "executiveDesc":"En lugar de desperdiciar en clase ejecutiva, invierta en vuelos ejecutivos"
            },
        


        }


      }
    }
  });

export default i18n;
