import React from 'react';
import { gridImage } from '../assets';
import { useTranslation } from 'react-i18next';
import '../../i18n'

export default function ThirdSection() {
   const {t,i18n}=useTranslation()
   return (
      <div className="w-full py-12 h-full  mt-[4rem] bg-gray-200 ">
       <div className="w-full mx-auto h-full my-6 align-center flex md:justify-center justify-start md:items-center items-start py-5 md:px-10 px-5  gap-2">
         <div className=" md:w-[50%] w-full md:mx-auto mx-2 h-full flex flex-col md:space-y-10 space-y-5  items-start justify-center">
            <div className="md:text-4xl text-2xl font-bold">
               {t('quality.qualityText')}
               </div>
            <div className="flex flex-col items-center gap-4 md:flex-row space-x-2">
               <div className="flex   flex-col gap-2 md:w-1/2 w-full">
                  <div className="font-semibold md:text-2xl text-lg">  {t('quality.cert')}
                     
                  </div>
                  <div className='md:text-lg text-base'>
                  {t('quality.certDesc')}
                  </div>
               </div>
               <div className="flex flex-col gap-2 md:w-1/2 w-full">
                  <div className="font-semibold md:text-2xl text-lg">{t('quality.catering')}</div>
                  <div className='md:text-lg text-base'>
                  {t('quality.cateringDesc')}
                  </div>
               </div>
            </div>
            <div className="flex flex-col items-center gap-4 md:flex-row space-x-2">
               <div className="flex flex-col gap-2 md:w-1/2 w-full">
                  <div className="font-semibold md:text-2xl text-lg">{t('quality.access')}</div>
                  <div className='md:text-lg text-base'>
                  {t('quality.accessDesc')}
                  </div>
               </div>
               <div className="flex flex-col gap-2 md:w-1/2 w-full">
                  <div className="font-semibold md:text-2xl text-lg">{t('quality.executive')}</div>
                  <div className='md:text-lg text-base'>
                  {t('quality.executiveDesc')}
                  </div>
               </div>
            </div>
         </div>
         <div className=" mr-4  md:block hidden">
            <img
               src={gridImage}
               className=" w-[500px] object-cover"
               alt=""
            />
         </div>
         </div>
      </div>
   );
}
