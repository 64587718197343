import React from 'react';

function AboutUsPage() {
   return (
      <div>
         <div className="w-screen min-h-[calc(100vh-64px)] aboutus flex justify-center items-center">
            <div className="w-[80%] text-center md:text-4xl text-xl md:leading-loose leading-relaxed">
               En Mobius, creemos en trascender los límites tradicionales del
               viaje aéreo. Fundada con la visión de ofrecer un lujo,
               conveniencia y seguridad inigualables,
               <strong> Mobius es tu opción principal</strong>
            </div>
         </div>
         <div className="w-screen h-screen flex flex-col">
            <div className="flex md:flex-row flex-col w-full h-1/2">
               <div className="md:text-2xl text-lg text-center leading-relaxed md:w-1/2 w-full md:h-full h-1/2 flex justify-center items-center md:p-20 p-5 bg-mobuis-gray">
                  Planifica tu vuelo de acuerdo a tus horarios y destinos
                  preferidos, con la flexibilidad que solo la aviación privada
                  puede ofrecer.
               </div>
               <div className="md:w-1/2 w-full md:h-full h-1/2 text-xl text-center p-20 bg-gray-500 flex justify-center items-center">
                  img placeholder
               </div>
            </div>
            <div className="md:flex w-full h-1/2 hidden">
               <div className="w-1/2 text-xl text-center p-20 bg-gray-500 flex justify-center items-center">
                  img placeholder
               </div>
               <div className="text-2xl text-center leading-relaxed w-1/2 flex justify-center items-center p-20 bg-mobuis-gray">
                  Disfruta de la comodidad y exclusividad de nuestros jets
                  privados, equipados con las últimas comodidades y tecnología.
               </div>
            </div>
            <div className="md:hidden w-full h-1/2 flex flex-col">
               <div className="text-lg text-center leading-relaxed w-full h-1/2 flex justify-center items-center md:p-20 p-5 bg-mobuis-gray">
                  Disfruta de la comodidad y exclusividad de nuestros jets
                  privados, equipados con las últimas comodidades y tecnología.
               </div>
               <div className="w-full h-1/2 text-xl text-center p-20 bg-gray-500 flex justify-center items-center">
                  img placeholder
               </div>
            </div>
         </div>
         <div className="w-screen h-screen bg-black flex flex-col justify-evenly items-center md:p-20 p-5 text-center">
            <div className='md:text-7xl text-5xl font-bold text-white'>Únete a la Experiencia Mobius</div>
            <div className='bg-mobius-golden w-full py-0.5 rounded-lg'></div>
            <div className='md:text-4xl text-xl text-white/80'>Descubre el arte de volar en privado con Mobius. Te prometemos una experiencia tan única como tú. Confía en nosotros para llevarte a donde necesitas estar, con elegancia y facilidad.</div>
         </div>
         <div className='w-screen h-screen text-center md:p-20 p-5 flex justify-center  items-center aboutusbg2'>
            <div className='md:text-4xl text-lg leading-relaxed'>Estamos comprometidos a reducir nuestro impacto ambiental. Invertimos en las últimas tecnologías y prácticas para garantizar que nuestras operaciones sean lo más ecológicas posible. Desde aeronaves eficientes en combustible hasta prácticas sostenibles a bordo, nos dedicamos a hacer una diferencia positiva.</div>
         </div>


         <div className='bg-gray-200 flex md:flex-row flex-col   md:p-12 p-8 md:py-[7rem]'>
            <div className='md:border-r-2 text-center md:text-left md:border-b-0 border-mobius-golden border-r-0 border-b-2 text-lg md:px-[9rem] px-6 py-8 md:py-0'>
            &ensp;  &emsp;Según estimaciones, el aprovechamiento de un vuelo empty leg puede reducir hasta en un 30-50% las emisiones de CO2 por pasajero en comparación con un vuelo privado tradicional, ya que se maximiza la eficiencia del combustible. 
            </div>
            <div className=' text-center md:text-left  md:px-[9rem] px-6 py-8 md:py-0   text-lg'>
            Además, al optimizar el uso de estos vuelos, se pueden evitar hasta 15 toneladas de CO2 por año por cada jet que opera bajo esta modalidad, dependiendo de la frecuencia y la ruta del avión.
            </div>
         </div>

         <div className='flex flex-col md:w-[85%] w-full mx-auto my-16  md:p-8 md:px-14 px-8 gap-8'>
            <div className='text-4xl font-bold text-center'>
               Contacto
            </div>

            <div className='text-lg mt-6'>
            Para cualquier consulta, asistencia o información adicional, no dudes en contactarnos: 
            </div>
            <div  className='text-lg'>
            socios@mobiusfly.com
            </div>
            <div className='text-lg'>
           <span className='font-bold text-lg'>Horario de atención:</span>  Lunes a Viernes de 10 a 11 am
            </div>
            <div className='text-lg'>
            Estamos disponibles para ayudarte con cualquier necesidad relacionada con tu vuelo o servicio con Mobius. Nos comprometemos a responder a tus consultas lo más rápido posible
            </div>


         </div>
      </div>
   );
}

export default AboutUsPage;
