import React from 'react';
import TripComponent from '@/pages/MyTrips/TripComponent';
import { ReservationData } from '../data/ReservationData';
import { useNavigate } from 'react-router-dom';
function BoletosClient() {
   let navigate=useNavigate()
   const showDetails=(id)=>{
      navigate('/my-trips/trip-details', { state: { resDetails: ReservationData[id] } })
    }
   return (
      <div className="overflow-y-auto max-h-[500px] w-full no-scrollbar gap-5 text-black">
<div className="flex mt-8 flex-col gap-6 w-full mx-auto">
                {ReservationData.map((reservation,index)=>(
                  <>
                 
                  {reservation.flights[1]?(
                    <div>
                      <TripComponent
               originCity={reservation?.flights[1].origin_details.city}
               destinationCity={reservation?.flights[1].destination_details.city}
               origin={reservation?.flights[1].origin_details.iata_code}
               destination={reservation?.flights[1].destination_details.iata_code}
               arrival={reservation?.flights[1].arrival_time.slice(0,5)}
               departure={reservation?.flights[1].departure_time.slice(0,5)}
               flightStatus={reservation?.flights[1].status}
               airline={reservation?.flights[1].airline}
               Date={reservation?.flights[1].departure_date}
               seatNo={reservation?.tickets[1].seat_number}
               flightNo={reservation?.tickets[1].flight}
               dataId={index}
               isSmall={true}
               showDetails={showDetails}
               />
                   <TripComponent
               originCity={reservation?.flights[0].origin_details.city}
               destinationCity={reservation?.flights[0].destination_details.city}
               origin={reservation?.flights[0].origin_details.iata_code}
               destination={reservation?.flights[0].destination_details.iata_code}
               arrival={reservation?.flights[0].arrival_time.slice(0,5)}
               departure={reservation?.flights[0].departure_time.slice(0,5)}
               flightStatus={reservation?.flights[0].status}
               airline={reservation?.flights[0].airline}
               Date={reservation?.flights[0].departure_date}
               seatNo={reservation?.tickets[0].seat_number}
               flightNo={reservation?.tickets[0].flight}
               dataId={index}
               isSmall={true}
               showDetails={showDetails}
               />
                
                    </div>
                  ):(
                    <div>
                        <TripComponent
               originCity={reservation?.flights[0].origin_details.city}
               destinationCity={reservation?.flights[0].destination_details.city}
               origin={reservation?.flights[0].origin_details.iata_code}
               destination={reservation?.flights[0].destination_details.iata_code}
               arrival={reservation?.flights[0].arrival_time.slice(0,5)}
               departure={reservation?.flights[0].departure_time.slice(0,5)}
               flightStatus={reservation?.flights[0].status}
               airline={reservation?.flights[0].airline}
               Date={reservation?.flights[0].departure_date}
               seatNo={reservation?.tickets[0].seat_number}
               flightNo={reservation?.tickets[0].flight}
               dataId={index}
               isSmall={true}
               showDetails={showDetails}
               />
                    </div>
                  )
                  }
                  </>
               
                )

                )

                }
             
             
              </div>
        
      </div>
   );
}

export default BoletosClient;
