import React, { useRef, useEffect, useState } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';
import { imagePlane, grid2 } from '@/components/assets';
import { ChevronLeft, ChevronRight, UploadFileIcon } from '@/components';
import axios from 'axios';
import FleetSelection from './FleetSelection.jsx';
import AirportSelection from './AirportSelection.jsx';
import PlaneDetailsSelection from './PlaneDetailsSelection.jsx';
import SeatSelection from './SeatSelection.jsx';
import CrewSelection from './CrewSelection.jsx';
import { AirportData } from '../../components/data/AirportData.js';
import { toast } from 'react-toastify';
import { useAuth } from '@/components';
import Loading from '@/components/Loader/Loading.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const OwnerReg = () => {
   const gridImage = imagePlane;
   const gridImage2 = grid2;
   let sliderRef = useRef(null);
   const { setUser, setId,isLoggedIn } = useAuth();
   const id = localStorage.getItem('USER_ID');
   const userId = parseInt(localStorage.getItem('USER_ID'), 10);
   const token = localStorage.getItem('ACCESS_TOKEN');
   const [loading, setLoading] = useState(false); // Loading state
   // const [userLogged, setUserLogged] = useState(false);
   const [userData, setUserData] = useState(null);
   const [ownerId, setOwnerId] = useState('');
   // Fleet Section
   const [fleetData, setFleetData] = useState('');
   const [fleetName, setFleetName] = useState(null);
   // const [ownerData, setOwnerData] = useState([]);
   // Seats Section
   const [selectedSeats, setSelectedSeats] = useState(null);
   const seatOptions = [2, 3, 4, 5, 6, 7, 8];
   const [activeSlide, setActiveSlide] = useState(0);
   const totalSlides = 9;
   const [showSelect, setShowSelect] = useState(false);
   // Airports Section
   const [selectedCity, setSelectedCity] = useState('');
   const [query, setQuery] = useState('');
   const [selectedBase, setSelectedBase] = useState(null);
   const [fleetId, setFleetId] = useState(null);
   const [showInput, setShowInput] = useState(false);
   //Plane Details Section
   const [year, setYear] = useState('');
   const [model, setModel] = useState('');
   const [tailNumber, setTailNumber] = useState('');
   //Crew Section
   const [crewName, setCrewName] = useState('');
   const [crewLastName, setCrewLastName] = useState('');
   const [crewLicense, setCrewLicense] = useState('');
   const [crewRole, setCrewRole] = useState('First Officer');
   // Document section

   const routerLocation = useLocation();
   const { state } = routerLocation;
   const { email, password, name, first_surname, second_surname, date_of_birth, telephone } = state || {};
   const [airplaneImage, setAirplaneImage] = useState(null); // Store a single file
const [ownerDocument, setOwnerDocument] = useState(null);
const [imagePreview, setImagePreview] = useState(''); // Store a single preview URL
const [documentName, setDocumentName] = useState('');
 
   const handleImageUpload = (e) => {
      const file = e.target.files[0];
      setAirplaneImage(file);
   
      // Set image preview
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
   };
   
   const handleDocumentUpload = (e) => {
      const file = e.target.files[0];
      setOwnerDocument(file);
      setDocumentName(file.name);
   };
 
 
   let navigate=useNavigate();
   //Fleet Section Functions
   const handleFleetNameChange = (e) => {
      setFleetName(e.target.value);
   };

//Set values to default values
   const SetValues=()=>{
    setShowInput(false)
    setFleetId(null)
    setSelectedSeats(null)
    setSelectedBase(null)
    setSelectedCity(null)
    setShowSelect(null)
    setTailNumber('')
    setModel('')
    setYear('')
    setCrewLastName('')
    setCrewLicense('')
    setCrewName('')
    setCrewRole('First Officer')
   }
   



const uploadPlaneDocs = async (token_t, planeId) => {
   const formData = new FormData();
   formData.append('plane', planeId); // Plane ID

   // Append the single image file only if it is provided
   if (airplaneImage) {
      formData.append('image', airplaneImage); // Single image file
   }
   if (ownerDocument) {
      formData.append('document', ownerDocument); // Document file
   }

   try {
      const response = await axios.post('/api/media/plane-docs/', formData, {
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token_t}`, // Replace with your actual token
         },
      });
      if (response.status === 201) {
         console.log('Plane docs upload successful:', response.data);
         toast.success('Plane documents uploaded Successfully.', {
            position: 'bottom-right',
            autoClose: 2000,
         });
      }

   } catch (error) {
      console.error('Error uploading plane docs:', error.response ? error.response.data : error.message);
   }
};

   const handleCrewData = async (token_t) => {
      if (!crewName) {
         toast.error('Name of crew member is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!crewRole) {
         toast.error('Role of crew member is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!crewLicense) {
         toast.error('Role of crew member is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
         axios
            .post(`/api/crew/crew/`,{ 
               name: crewName, 
               surname: crewLastName,
                role: crewRole,
               licenseNumber: crewLicense }, {
               headers: {
                  'Authorization': `Bearer ${token_t}`
               }
            })
            .then((response) => {
                
              console.log(response.data)
              toast.success('You have created your crew Successfully!', {
               position: 'bottom-right',
               autoClose: 2000,
            });
              
            })
            .catch((error) => {
               console.error('Crew getting error', error.response ? error.response.data : error.message);
              
            });
 
   };
   
// Function to get owner Id
      const fetchOwnerData = async (token_t) => {
         try {
            const response = await axios.get('/api/accounts/owners',
               {
                  headers: {
                   'Authorization': `Bearer ${token_t}`
                  }
               }
            );
            if (Array.isArray(response.data)) {
               // setOwnerData(response.data);
               const owner = response.data.find(item => (item.user === userId))?.id  || null;
               setOwnerId(owner);
               
               return owner;
            } else {
               console.error('Unexpected data format');
            }
         } catch (error) {
            console.error('Error fetching owner data:', error);
         }
      };
      const Createfleet=()=>{
         if (!fleetName ) {
            toast.error('Fleet name is required.', {
               position: 'bottom-right',
               autoClose: 2500,
            });
            return;
         }
         next()
      }
      fetchOwnerData(token);
//  Function for Fleet Creation
const handleFleetNameSubmit = async (token_t) => {
   // Check if required fields are not empty or null
  

   // Owner ID is retrieved from another function, ensure it's not null or empty
   const owner_Id = await fetchOwnerData(token_t);
   if (!owner_Id) {
      toast.error('Owner ID is missing.', {
         position: 'bottom-right',
         autoClose: 2000,
      });
      return;
   }

   try {
      const postData = {
         owner_id: owner_Id,
         fleetName: fleetName,
      };
      const response = await axios.post('/api/fleet/fleets/', postData, {
         headers: {
            'Authorization': `Bearer ${token_t}`,
         },
      });

      if (response.status === 201) {
         console.log('Successful fleet creation:', response.data);
         setFleetId(response.data.id);
         const fleet_id = response.data.id;

         toast.success('You have created your new fleet', {
            position: 'bottom-right',
            autoClose: 2000,
         });

         // Validate airplane data before proceeding
         

         // Proceed with airplane registration
         try {
            const planeData = {
               fleet_id: fleet_id,
               planeModel: model,
               registrationNumber: tailNumber,
               capacity: selectedSeats,
               completedFlights: 0,
               yearOfManufacture: year,
               homeAirport: selectedBase,
            };
            const response = await axios.post('/api/fleet/planes/', planeData, {
               headers: {
                  'Authorization': `Bearer ${token_t}`,
               },
            });

            if (response.status === 201) {
               console.log('Successful plane registration:', response.data);
              await uploadPlaneDocs(token_t,response.data.id)
              if(!isLoggedIn){
               handleCrewData(token_t)
              }
               

               toast.success('You have created your new plane', {
                  position: 'bottom-right',
                  autoClose: 2000,
               });
               SetValues();
               navigate('/');
            } else {
               toast.error('Plane registration failed.', {
                  position: 'bottom-right',
                  autoClose: 5000,
               });
               navigate('/');
            }
         } catch (error) {
            console.error('Plane Registration error', error.response ? error.response.data : error.message);
            navigate('/');
         }
      } else {
         console.log('Fleet creation error.');
      }
   } catch (error) {
      console.error('Fleet API error:', error.response ? error.response.data : error.message);
   }
};
//Function to filter fleet data
   useEffect(() => {
      if (id && token) {
         axios
            .get(`/api/fleet/fleets/`, {
               headers: {
                  'Authorization': `Bearer ${token}`
               }
            })
            .then((response) => {
               const allFleetData = response.data;
               const filteredFleetData = allFleetData.filter(fleet => fleet.owner === ownerId); 
               setFleetData(filteredFleetData);
            })
            .catch((error) => {
               console.error('There was an error fetching the user data!', error);
               toast.error('Could not load user information', {
                  position: 'bottom-right',
                  autoClose: 2000,
               });
            });
      }
   }, [id, token, fleetId,ownerId]);
   
   //Airports Section Functions
   const cities = [...new Set(AirportData.map(data => data.city))]; // Extract unique cities

   const filteredCities = query === ''
      ? cities
      : cities.filter(city =>
         city.toLowerCase().includes(query.toLowerCase())
      );

   const handleSelectBase = (index) => {
      setSelectedBase(index);
      console.log(selectedBase)
   };

   const filteredAirports = selectedCity
      ? AirportData.filter(data => data.city === selectedCity)
      : [];

   const handleToggle = () => {
      setShowSelect(!showSelect);
   };

   // Seat Selection section
   const handleSeatSelection = (number) => {
      setSelectedSeats(number);
   };
   useEffect(() => {
      if (id && token) {
         axios
            .get(`/api/accounts/users/${id}`, {
               headers: {
                  'Authorization': `Bearer ${token}`
               }
            })
            .then((response) => {
               // Handle response data
               console.log(response.data);
               setUserData(response.data);
            })
            .catch((error) => {
               console.error(
                  'There was an error fetching the user data!',
                  error,
               );
               toast.error('Could not load user information', {
                  position: 'bottom-right',
                  autoClose: 2000,
               });
            });
      }
   }, [id, token]);
  // Plane Registration
   const registerPlane = async () => {
      if (!model) {
         toast.error('Plane model is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!tailNumber) {
         toast.error('Registration number is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!selectedSeats) {
         toast.error('Seat capacity is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!year) {
         toast.error('Year of manufacture is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!selectedBase) {
         toast.error('Home airport is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      setLoading(true); // Start loader

      if(isLoggedIn){
         await handleFleetNameSubmit(token)
      }
      else{
      try {
         const postData = {
            email: email,
            password: password,
            name: name,
            first_surname: first_surname,
            second_surname: second_surname,
            owner: true,
            date_of_birth: date_of_birth,
            telephone: telephone,
            passager: false,
            block: false,
         };
         const response = await axios.post('/api/accounts/users/register/', postData, {
         });
         if (response.status === 201) {
            console.log('Successful registration:', response.data);
            toast.success('Successful registration', {
               position: 'bottom-right',
               autoClose: 2000,
            });
            try {
               const response = await axios.post('/api/accounts/login/', {
                  email: email,
                  password: password,
               });
               if (response.status === 200) {
                  localStorage.setItem('ACCESS_TOKEN', response.data.access);
                  localStorage.setItem('USER_ID', response.data.id);
                  // localStorage.setItem('REFRESH_TOKEN', response.data.refresh);
                  setUser(response.data.email);
                  setId(response.data.id);
                  const token_t= response.data.access;
                  console.log(token_t)
                 await handleFleetNameSubmit(token_t)
               } else {
                  console.log("error logging in")
               }
            } catch (error) {
               console.log("login api failed")
            }
         } else {
            toast.error('Registration failed. Please check your credentials and try again.', {
               position: 'bottom-right',
               autoClose: 5000,
            });
         }
      } catch (error) {
         console.error(
            'Registration error:',
            error.response ? error.response.data : error.message
         );
         toast.error(`Registration Failed: ${error.response?.data?.message}`, {
            position: 'bottom-right',
            autoClose: 5000,
         });
      }
    }
      
         setLoading(false); // Stop loader
      
   }
    //Slider Settings
   const arrowSytle = 'absolute top-1/2 p-1 px-2 rounded-full h-[40px] w-[40px] md:h-[50px] md:w-[50px] z-10 ';
   const next = () => {

      if(isLoggedIn){
      if(activeSlide !==4){
          sliderRef.slickNext();}
          else{
            sliderRef.slickGoTo(6)
          }
         }
         else{
            sliderRef.slickNext();
         }
       setShowInput(false)
   };
   const prev = () => {

     if(isLoggedIn){
         if(activeSlide !==6){
          sliderRef.slickPrev();}
          else{
            sliderRef.slickGoTo(4)
          }
     }
     else{
      sliderRef.slickPrev();
     }
   };
   function SamplePrevArrow(props) {
      return (
         <ChevronLeft
            onClick={prev}

            className={`${arrowSytle}transition-opacity duration-300 ease-in-out ${activeSlide > 0
               ? 'opacity-100 md:left-10 left-1 cursor-pointer'
               : 'opacity-0 pointer-events-none'
               }`}
         />
      );
   }
   function SampleNextArrow() {

      return (
         <ChevronRight
            onClick={next}

            className={`${arrowSytle}transition-opacity duration-300 ease-in-out ${activeSlide < totalSlides - 1 && activeSlide !== 0 && activeSlide!==7
               ? 'opacity-100 md:right-10 right-1 cursor-pointer'
               : 'opacity-0 pointer-events-none'
               }`}
         />
      );
   }
   const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipe: false,
      beforeChange: (current, next) => {
         setActiveSlide(next);
         console.log(activeSlide);
      },
   };
   return (
      <div>

        {loading ? (
             
                 <Loading/>
              
             ):(
               <Slider ref={(slider) => {
                  sliderRef = slider;
               }}
                  {...settings}>
         
                 
                  {/* Introduction Slide */}
                  <div key={1}>
                     <div className="h-[100vh] w-screen flex flex-col pt-12 items-center justify-evenly md:px-20 px-2">
                        <div className="md:text-6xl mb-4 text-center text-4xl font-bold ">
                           Bienvenido...  {userData ? userData.name : ''}
                        </div>
                        <div className="grid md:grid-cols-2">
                           <div className="flex flex-col md:gap-5 gap-10 justify-center">
                              <div className="bg-mobuis-gray rounded-lg md:px-10 px-5 md:mx-2 mx-4 py-2 md:py-0">
                                 <div className="text-black md:text-xl md:py-3">
                                    Estás cerca de emprender el viaje que te dará lo mejor para tu avión y para ti.
                                 </div>
                              </div>
                              <div className="bg-mobuis-gray rounded-lg md:px-3 md:mx-2 mx-4 px-5 py-4 text-base text">
                                 <div>
                                    Simplemente haz el registro de tu aeronave y estás listo para poder hacer de este la nueva casa tanto para ti, como para tu aeronave.
                                 </div>
                              </div>
                           </div>
                           <div className="md:flex flex-col gap-5 justify-around items-center hidden">
                              <img src={gridImage} alt="" className="w-auto h-48" />
                           </div>
                        </div>
                        <button
                           onClick={next}
                           className="outline outline-2 outline-mobius-golden text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-12 mt-10 text-xl rounded-xl p-2"
                        >
                           Comenzar
                        </button>
                     </div>
                  </div>
                  {/* Fleet Slide */}
                  <div key={2}>
                     <FleetSelection fleetName={fleetName} 
                     handleFleetNameChange={handleFleetNameChange} 
                     next={Createfleet}
                      handleToggle={handleToggle} 
                      showSelect={showSelect} 
                      setFleetId={setFleetId}
                      fleetId={fleetId}
                      
                      fleetData={fleetData}
                     />
         
                  </div>
         
                  {/* Seat Slide */}
                  <div key={3}>
                     <SeatSelection
                        seatOptions={seatOptions}
                        selectedSeats={selectedSeats}
                        showInput={showInput}
                        handleSeatSelection={handleSeatSelection}
                        setShowInput={setShowInput}
                        setSelectedSeats={setSelectedSeats}
                        next={next}
                     />
                  </div>
                  {/* Airport Slide */}
                  <div key={4}>
                     <AirportSelection
                        selectedCity={selectedCity}
                        setSelectedCity={setSelectedCity}
                        filteredCities={filteredCities}
                        filteredAirports={filteredAirports}
                        handleSelectBase={handleSelectBase}
                        selectedBase={selectedBase}
                        height={true}
                        heading={'Where is your Base'}
                        subheading={'Select City to get Airports'}
                        cities={cities}
                     />
                  </div>
         
                  {/* Plane Details Slide */}
                  <div key={5} className='flex flex-col items-center pb-6'>
                     <PlaneDetailsSelection
                        year={year}
                        model={model}
                        tailNumber={tailNumber}
                        setYear={setYear}
                        setModel={setModel}
                        setTailNumber={setTailNumber}
                              
                     />
                  </div>
                  {/* Crew Slide */}
                  <div key={6}>
                     <CrewSelection crewName={crewName} crewLastName={crewLastName} crewRole={crewRole} 
                         setCrewName={setCrewName} setCrewLastName={setCrewLastName} setCrewLicense={setCrewLicense} setCrewRole={setCrewRole} crewLicense={crewLicense} 
                     />
                  </div>
         
                  {/* Document Upload Slide */}
                  <div key={7}>
       <div className="h-[100vh] flex flex-col justify-evenly items-center md:py-20 px-5 md:text-center">
          <div>
            <div className="font-bold md:text-5xl text-center md:text-left text-3xl mb-5">
              Sube las fotos de tu Aeronave
            </div>
            <div className='text-center md:text-left'>
              Sube al menos 5 fotos de tu aeronave como se muestra en el ejemplo
            </div>
          </div>
          <div className="flex justify-center mx-4 items-center gap-10">
            <div className="outline outline-3 outline-mobius-golden rounded-xl flex flex-col justify-evenly items-center py-3 px-5 cursor-pointer gap-3">
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
                id="airplane-image-upload"
              />
              <label htmlFor="airplane-image-upload" className="cursor-pointer">
                <UploadFileIcon className="w-12 h-12" />
                <div>Drag and drop or click to upload</div>
              </label>
            </div>
            <div className="w-1/3 md:block hidden">
              <img src={gridImage2} alt="Example" className="" />
            </div>
          </div>
          <div className="flex flex-wrap justify-start mt-5">
          {imagePreview &&
                    <img
                    src={imagePreview}
                    alt={`plane `}
                    className="md:w-[250px] w-[150px]  object-cover rounded-lg m-2"
                  />
               }
             
         
          </div>
        </div>
      </div>

      {/* Upload Owner Document */}
      <div key={8}>
        <div className="h-[100vh] min-h-[85vh] flex flex-col justify-evenly items-center md:py-15 py-10 px-5 md:text-center">
          <div className="">
            <div className="font-bold md:text-5xl text-3xl mb-5">
              Sube tu documento de propietario
            </div>
            <div className="md:text-2xl mb-5">
              El documento de propietario debe .....
            </div>
          </div>
          <div className="flex justify-center items-center gap-10">
            <div className="outline outline-3 outline-mobius-golden rounded-xl flex flex-col justify-evenly items-center md:py-6 md:px-10 py-3 px-5 cursor-pointer gap-5">
              <input
                type="file"
                accept=".pdf,.doc,.docx,.jpg,.png"
                onChange={handleDocumentUpload}
                className="hidden"
                id="owner-document-upload"
              />
              <label htmlFor="owner-document-upload" className="cursor-pointer">
                <UploadFileIcon className="md:w-16 md:h-16 w-12 h-12" />
                <div className="md:text-xl">Drag and drop or click to upload</div>
              </label>
            </div>
          </div>
          {documentName && (
            <div className="mt-5 text-center">
              <p className="text-xl">Uploaded Document: {documentName}</p>
            </div>
          )}
        </div>
        <div className=' border-2 border-mobius-golden cursor-pointer mx-auto mb-[5rem] text-center w-[200px]   p-3 hover:bg-mobius-golden hover:text-white rounded-md' onClick={registerPlane}>
        Register Plane
    </div>
      </div>

                  {/* Final Slide */}
                  {/* <div key={9}>
                     <div>
                        <div className="md:h-[100vh] min-h-[80vh] flex flex-col justify-evenly items-center py-20">
                           <div className="text-3xl text-center mt-2">
                              Congratulations!
                           </div>
                           <div className="md:text-5xl text-3xl  font-bold text-center mt-6">
                              You Have Completed Your  <span className='block md:hidden'>Registraion</span>
                           </div>
                           <div className="md:text-5xl hidden md:block text-3xl  font-bold text-center mt-2">
                              Registration
                           </div>
                           <Link
                              to="/"
                              className="outline outline-2 outline-mobius-golden text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-12 mt-10 text-xl rounded-lg flex justify-center items-center px-5 py-2">
                              <div>Continue</div>
                           </Link>
                        </div>
                     </div>
                  </div> */}
               </Slider>
         
             )
             
             }   
            </div>

   );
};

export default OwnerReg;