import React from 'react';
import SetPassword from '@/components/SetPassword/SetPassword';


const HandlePassword=()=> {
   return (
      <div className='backg-login md:pt-[4rem] md:py-16 h-full md:h-auto md:mb-[0rem] mb-[8rem]   w-full  flex justify-center items-center'>
       
         <SetPassword />
         
         
      </div>
   );
}

export default HandlePassword;
