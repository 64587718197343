import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // your i18n config
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GridLoader } from './components';
import BookFlight from './pages/ClientView/BookFlight';
import SearchFlightComp from './pages/ClientView/SearchFlightComp';
import PassengerDetails from './pages/ClientView/PassengerDetails';
import OrderSummary from './pages/ClientView/OrderSummary';
import HandlePassword from './pages/SetPassword';
import OrderConfirmation from './pages/ClientView/OrderConfirmation';
import MyTrips from './pages/MyTrips/MyTrips';
import TripDetails from './pages/MyTrips/TripDetails';

import {
   LandingPage,
   Login,
   FlightReg,
   OwnerReg,
   ProfilePage,
   Layout,
   Register,
   NewLandingPage,
   AboutUsPage,
 

} from './pages';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
   {
      element: <Layout />,
      children: [
         {
            path: '/',
            element: <NewLandingPage />,
         },
         {
            path: '/login',
            element: <Login />,
         },
         {
            path: '/set-password',
            element: <HandlePassword />,
         },
         {
            path: '/aboutus',
            element: <AboutUsPage />,
         },
         {
            path: '/my-trips',
            element: <MyTrips />, // Main MyTrips component
           
         },
         {
            path: '/my-trips/trip-details',
            element: <TripDetails />, // Main MyTrips component
           
         },
         {
            path: '/flight-registration',
            element: <FlightReg />,
         },
         {
            path: '/plane-reg',
            element: <OwnerReg />,
         },
         {
            path: '/profile',
            element: <ProfilePage />,
         },
         {
            path: '/register',
            element: <Register />,

         },
          {
            path: '/search-flight',
            element: <SearchFlightComp />,

         },
         {
            path: '/book-flight',
            element: <BookFlight />,

         },
        
         {
            path: '/passenger-details',
            element: <PassengerDetails />,

         },
         {
            path: '/order-summary',
            element: <OrderSummary />,

         },
         {
            path: '/payment-confirmation',
            element: <OrderConfirmation />,

         },
      ],
   },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} fallbackElement={<GridLoader />} />
   </I18nextProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
