import React from 'react';
import {
   planeexample1,
   planeexample2,
   planeexample3,
} from '@/components/assets';

const planesData = [
   { id: 1, name: 'Hawker 800', image: planeexample1, matricula: 'N1234' },
   { id: 2, name: 'Citation V', image: planeexample2, matricula: 'N5678' },
   { id: 3, name: 'Learjet 35', image: planeexample3, matricula: 'N91011' },
];

export default function PlanesOwner() {
   return (
      <div className="flex md:flex-row flex-col mt-8 md:mt-2 gap-6 justify-evenly items-center w-full h-full">
         {planesData.map((plane) => (
            <div
               key={plane.id}
               className="flex flex-col items-center gap-3 "
            >
               <img
                  src={plane.image}
                  alt={plane.name}
                  className="md:size-56 size-24 rounded-full shadow-lg"
               />
               <div className="font-bold">{plane.name}</div>
               <div className="text-sm text-mobius-dark-gray">
                  {plane.matricula}
               </div>
            </div>
         ))}
      </div>
   );
}
