import React, {  useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import axios from 'axios';
import { AirportData } from '../data/AirportData';

const AirportDropdown=({selectedCity,setSelectedCity})=>{
    const [query, setQuery] = useState('');
    const token = localStorage.getItem('ACCESS_TOKEN');

   //   useEffect(()=>{
   //    const fetchAirportData = async () => {
       
   // try {
   //          const response = await axios.get('/api/airports/', {
   //             headers: {
   //                'Authorization': `Bearer ${token}`, // Replace with your actual token
   //             },
   //          });
   //          if (response.status === 201) {
   //             console.log('airports data', response.data);
   //             setAirportData(response.data)
              
   //          }
      
   //       } catch (error) {
   //          console.error('Error getting airports:', error.response ? error.response.data : error.message);
   //       }
   //    };

   //    fetchAirportData()
   //   })
    const cities = [...new Set(AirportData.map(data => data.city))]; // Extract unique cities


    const filteredCities = query === ''
       ? cities
       : cities.filter(city =>
          city.toLowerCase().includes(query.toLowerCase())
       );
      
      
    return(
        <div className="flex  flex-col justify-center items-center  text-center">
       
        <div className="mb-4 relative w-[97%]">
           <Combobox value={selectedCity} onChange={setSelectedCity}>
              <Combobox.Input
                 className="py-2  border-2 rounded-md px-2 text-[13px] text-black  w-full"
                 onChange={(event) => setQuery(event.target.value)}
                 displayValue={(city) => city}
                 placeholder="Select a City"
              />
              <Combobox.Options className="mt-1 absolute z-[999] bg-gray-100 border rounded-md max-h-60 overflow-auto">
                 {filteredCities.map((city, index) => (
                    <Combobox.Option
                       key={index}
                       value={city}
                       className={({ active }) => `px-4 py-2 cursor-pointer ${active ? 'bg-mobius-golden text-white' : ''}`}
                    >
                       {city}
                    </Combobox.Option>
                 ))}
              </Combobox.Options>
           </Combobox>
        </div>
        
     </div>
    )
}
export default AirportDropdown