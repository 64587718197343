import React from 'react';
import { Disclosure } from '@headlessui/react';
import {
   Menu,
   CircleUserIcon,
   X,
   UserRound,
   Plane,
   PlaneTakeoff,
   Eye,
   CircleHelp,
   LogOut,
   Receipt
} from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '@/components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import '../../i18n'


function MenuUser() {
   let navigate = useNavigate();
   const {t,i18n}=useTranslation()

   const { user, isOwner } = useAuth();
   console.log(isOwner)




   const handleLogout = (e) => {
      e.preventDefault();
      navigate('/login');
      localStorage.removeItem('ACCESS_TOKEN');
      toast.success('Cierre de sesión exitoso', {
         position: 'bottom-right',
         autoClose: 2500,
      });
   };

   return (
      <div className="p-2 flex items-center relative">
         <Disclosure>
            {({ open }) => (
               <>
                  <Disclosure.Button className="flex items-center gap-2">
                     <CircleUserIcon size={20} color="white" />
                     {open ? (
                        <X size={20} color="white" />
                     ) : (
                        <Menu size={20} color="white" />
                     )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="bg-black absolute top-12 w-60 -right-5 rounded-bl-lg text-base z-20">
                     {({ close }) => (
                        <div className="flex flex-col space-y-7">
                           <div className="px-4 py-4 border-b-2 border-mobius-golden text-white font-bold">
                              {user}
                           </div>
                           <NavLink
                              to="/profile"
                              className="px-3 pt-1 text-white hover:text-gray-300 flex gap-2 items-center"
                              onClick={close}
                           >
                              <UserRound size={20} color="white" />
                              <span> {t('Menu.profile')}</span>
                           </NavLink>
                           {isOwner && (

                              <>
                                 <NavLink
                                    to="/plane-reg"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <Plane size={20} color="white" />
                                    <span>{t('Menu.planeReg')}</span>

                                 </NavLink>

                                 <NavLink
                                    to="/flight-registration"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <PlaneTakeoff size={20} color="white" />
                                    <span>{t('Menu.flightReg')}</span>

                                 </NavLink>
                              </>
                           )}

                           <NavLink
                              to="/book-flight"
                              className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                              onClick={close}
                           >
                              <Receipt size={20} color="white" />
                              <span>{t('Menu.flightBook')}</span>

                           </NavLink>
                           <NavLink
                              to="/"
                              className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                              onClick={close}
                           >
                              <Eye size={20} color="white" />
                              <span>{t('Menu.seeFlight')}</span>
                           </NavLink>
                           <NavLink
                              to="/"
                              className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                              onClick={close}
                           >
                              <CircleHelp size={20} color="white" />
                              <span>{t('Menu.howItWork')}</span>
                             
                           </NavLink>
                           <NavLink
                              to="/"
                              className="px-3 pb-4 text-white hover:text-gray-300 flex gap-2 items-center"
                              onClick={handleLogout}
                           >
                              <LogOut size={20} color="white" />
                              <span>{t('Menu.logout')}</span>
                           </NavLink>
                        </div>
                     )}
                  </Disclosure.Panel>
               </>
            )}
         </Disclosure>
      </div>
   );
}

export default MenuUser;
