import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
const AirportSelection = ({ selectedCity, setSelectedCity, heading, height, subheading, cities, filteredAirports, handleSelectBase, selectedBase }) => {
   const [query, setQuery] = useState('');
   const filteredCities = query === ''
      ? cities
      : cities.filter(city =>
         city.toLowerCase().includes(query.toLowerCase())
      );
   return (
      <div className={`flex mt-8   ${height ? 'h-[100vh]' : ''} flex-col justify-center items-center md:px-28 text-center`}>
         <div className="md:mb-0 mb-5">
            <div className="font-bold md:text-5xl mt-8 text-3xl">
               {heading}
            </div>
            <div className="font-light px-4 text-gray-800 md:mt-10 mt-2 mb-8">
               {subheading}
            </div>
         </div>
         <div className="mb-4 relative w-[90%] md:w-1/2">
            <Combobox value={selectedCity} onChange={setSelectedCity}>
               <Combobox.Input
                  className="px-4 py-2 border-0 border-b-2 text-black focus:outline-none focus:border-b-2 w-full"
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(city) => city}
                  placeholder="Select a City"
               />
               {Array.isArray(filteredCities) && filteredCities.length > 0 && (
                  <Combobox.Options className="mt-1 w-[100%] absolute bg-gray-100 z-[999] border rounded-md max-h-60 overflow-auto">
                     {filteredCities.map((city, index) => (
                        <Combobox.Option
                           key={index}
                           value={city}
                           className={({ active }) => `px-4 py-2 cursor-pointer ${active ? 'bg-mobius-golden text-white' : ''}`}
                        >
                           {city}
                        </Combobox.Option>
                     ))}
                  </Combobox.Options>
               )}
            </Combobox>
         </div>
         {selectedCity && (
            <div className=" mt-8 md:gap-4 gap-2 flex items-center justify-center px-12">
               {filteredAirports.map((airport) => (
                  <button
                     key={airport.id}
                     className={`h-32 w-48 text-sm ${selectedBase === airport.id ? 'bg-mobius-golden text-white' : 'bg-white'} outline outline-2 outline-mobius-golden p-2 hover:bg-mobius-golden hover:text-white rounded-lg`}
                     onClick={() => handleSelectBase(airport.id)}
                  >
                     <span className="block font-bold">{airport.name}</span>
                     <span className="block mt-4">{airport.city}</span>
                  </button>
               ))}
            </div>
         )}
      </div>
   )
}
export default AirportSelection