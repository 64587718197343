import React from "react";
import { Link } from 'react-router-dom';

import Logo from '../assets/LogoTransp.png'

//Imported Icons
import { TfiFacebook } from "react-icons/tfi";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import '../LandingPage/cast.css'
import { useTranslation } from "react-i18next";
import '../../i18n'


const Footer = () => {
    const {t}=useTranslation()
    return (
        <div className="footerLanding">
            <div className="sectionContainerLanding containerLanding gridLanding">
                <div className="gridOneLanding">
                    <div className="logoDivLanding">
                        <img src={Logo} className="LogoLanding" alt="Logo mobius"/>
                    </div>
                    <p>{t('Footer.intro')}</p>
                    <div className="socialIconLanding flexLanding">
                        <TfiFacebook className="iconLanding"/>
                        <FaInstagram className="iconLanding"/>
                        <FaXTwitter className="iconLanding"/>
                        <FaLinkedinIn className="iconLanding"/>
                    </div>
                </div>

                <div className="footerLinksLanding">
                    <span className="linkTitleLanding">{t('Footer.about')}</span>
                    <li><Link to="/quienes-somos">{t('Footer.whoWeAre')}</Link></li>
                    <li><Link to="/aliados">{t('Footer.allies')}</Link></li>
                    <li><Link to="/sustentabilidad">{t('Footer.sus')}</Link></li>
                    <li><Link to="/clientes-satisfechos">{t('Footer.customer')}</Link></li>
                </div>

                

                <div className="footerLinksLanding">
                    <span className="linkTitleLanding">{t('Footer.info')}</span>
                    <li><Link to="/destinos">{t('Footer.flightStatus')}</Link></li>
                    <li><Link to="/hangares">{t('Footer.checkIn')}</Link></li>
                    <li><Link to="/hangares">{t('Footer.booking')}</Link></li>
                    <li><Link to="/hangares">{t('Footer.myFlights')}</Link></li>
                </div>

                <div className="footerLinksLanding">
                    <span className="linkTitleLanding">{t('Footer.legal')}</span>
                    <li><Link to="/politica-privacidad">{t('Footer.privacyPolicy')}</Link></li>
                    <li><Link to="/politica-cookies">{t('Footer.cookiesPolicy')}</Link></li>
                    <li><Link to="/aviso-privacidad">{t('Footer.privacyNote')}</Link></li>
                    <li><Link to="/terminos-condiciones">{t('Footer.terms')}</Link></li>
                </div>

                

            </div>
            <div className="copyRightdivLanding flexLanding">
                <p>@2024 Mobius</p>
                </div>
        </div>
    )
}

export default Footer