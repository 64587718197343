import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    let location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [client, setClient] = useState(false);
    const [id, setId] = useState(null);
    const [user, setUser] = useState(null);
  
   
    useEffect(() => {
        const owner = localStorage.getItem('isOwner') === 'true' ;
        
        setIsOwner(owner);
     }, []); 
     useEffect(() => {
        console.log(client)
     }, []); 
   
   
    useEffect(() => {
        const token = localStorage.getItem('ACCESS_TOKEN');
        setIsLoggedIn(!!token);
    }, [location]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn,client,setClient, isOwner, setIsOwner, id, setId, user, setUser}}>
            {children}
        </AuthContext.Provider>
    );
};
