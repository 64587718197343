export const    AirportData=[
    {
        "id": 1,
        "iata_code": "TLC",
        "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
        "city": "Toluca",
        "state_province": "Estado de Mexico",
        "country": "Mexico"
    },
    {
        "id": 2,
        "iata_code": "NTR",
        "name": "Aeropuerto Internacional Mariano Escobedo",
        "city": "Monterrey",
        "state_province": "Nuevo Leon",
        "country": "Mexico"
    },
    {
        "id": 3,
        "iata_code": "MTY",
        "name": "Aeropuerto Internacional del Norte",
        "city": "Monterrey",
        "state_province": "Nuevo Leon",
        "country": "Mexico"
    },
    {
        "id": 4,
        "iata_code": "CUU",
        "name": "Aeropuerto Internacional Roberto Fierro Villalobos",
        "city": "Chihuahua",
        "state_province": "Chihuahua",
        "country": "Mexico"
    },
    {
        "id": 5,
        "iata_code": "CJS",
        "name": "Aeropuerto Internacional Abraham Gonzalez",
        "city": "Ciudad Juarez",
        "state_province": "Chihuahua",
        "country": "Mexico"
    },
    {
        "id": 6,
        "iata_code": "SLP",
        "name": "Aeropuerto Internacional Ponciano Arriaga",
        "city": "San Luis Potosi",
        "state_province": "San Luis Potosi",
        "country": "Mexico"
    },
    {
        "id": 7,
        "iata_code": "AGU",
        "name": "Aeropuerto Internacional Licenciado Jesus Teran Peredo",
        "city": "Aguascalientes",
        "state_province": "Aguascalientes",
        "country": "Mexico"
    },
    {
        "id": 8,
        "iata_code": "NLU",
        "name": "Aeropuerto Internacional Felipe Angeles",
        "city": "Ciudad de Mexico",
        "state_province": "Ciudad de Mexico",
        "country": "Mexico"
    },
    {
        "id": 9,
        "iata_code": "BJX",
        "name": "Aeropuerto Internacional del Bajio",
        "city": "Leon",
        "state_province": "Guanajuato",
        "country": "Mexico"
    },
    {
        "id": 10,
        "iata_code": "QRO",
        "name": "Aeropuerto Internacional de Queretaro",
        "city": "Queretaro",
        "state_province": "Queretaro",
        "country": "Mexico"
    },
    {
        "id": 11,
        "iata_code": "LAP",
        "name": "Aeropuerto Internacional Manuel Marquez de Leon",
        "city": "La Paz",
        "state_province": "Baja California Sur",
        "country": "Mexico"
    },
    {
        "id": 12,
        "iata_code": "SJD",
        "name": "Aeropuerto Internacional de Los Cabos",
        "city": "San Jose del Cabo",
        "state_province": "Baja California Sur",
        "country": "Mexico"
    },
    {
        "id": 13,
        "iata_code": "MXL",
        "name": "Aeropuerto Internacional General Rodolfo Sanchez Taboada",
        "city": "Mexicali",
        "state_province": "Baja California",
        "country": "Mexico"
    },
    {
        "id": 14,
        "iata_code": "TIJ",
        "name": "Aeropuerto Internacional General Abelardo L. Rodriguez",
        "city": "Tijuana",
        "state_province": "Baja California",
        "country": "Mexico"
    },
    {
        "id": 15,
        "iata_code": "GDL",
        "name": "Aeropuerto Internacional Miguel Hidalgo y Costilla",
        "city": "Guadalajara",
        "state_province": "Jalisco",
        "country": "Mexico"
    },
    {
        "id": 16,
        "iata_code": "PVR",
        "name": "Aeropuerto Internacional Gustavo Diaz Ordaz",
        "city": "Puerto Vallarta",
        "state_province": "Jalisco",
        "country": "Mexico"
    },
    {
        "id": 17,
        "iata_code": "CUL",
        "name": "Aeropuerto Internacional Federal de Culiacan",
        "city": "Culiacan",
        "state_province": "Sinaloa",
        "country": "Mexico"
    },
    {
        "id": 18,
        "iata_code": "MZT",
        "name": "Aeropuerto Internacional General Rafael Buelna",
        "city": "Mazatlan",
        "state_province": "Sinaloa",
        "country": "Mexico"
    },
    {
        "id": 19,
        "iata_code": "HMO",
        "name": "Aeropuerto Internacional General Ignacio Pesqueira Garcia",
        "city": "Hermosillo",
        "state_province": "Sonora",
        "country": "Mexico"
    },
    {
        "id": 20,
        "iata_code": "TGZ",
        "name": "Aeropuerto Internacional Angel Albino Corzo",
        "city": "Tuxtla Gutierrez",
        "state_province": "Chiapas",
        "country": "Mexico"
    },
    {
        "id": 21,
        "iata_code": "OAX",
        "name": "Aeropuerto Internacional Xoxocotlan",
        "city": "Oaxaca",
        "state_province": "Oaxaca",
        "country": "Mexico"
    },
    {
        "id": 22,
        "iata_code": "CUN",
        "name": "Aeropuerto Internacional de Cancun",
        "city": "Cancun",
        "state_province": "Quintana Roo",
        "country": "Mexico"
    },
    {
        "id": 23,
        "iata_code": "CZM",
        "name": "Aeropuerto Internacional de Cozumel",
        "city": "Cozumel",
        "state_province": "Quintana Roo",
        "country": "Mexico"
    },
    {
        "id": 24,
        "iata_code": "VSA",
        "name": "Aeropuerto Internacional Carlos Rovirosa Perez",
        "city": "Villahermosa",
        "state_province": "Tabasco",
        "country": "Mexico"
    },
    {
        "id": 25,
        "iata_code": "VER",
        "name": "Aeropuerto Internacional General Heriberto Jara",
        "city": "Veracruz",
        "state_province": "Veracruz",
        "country": "Mexico"
    },
    {
        "id": 26,
        "iata_code": "MID",
        "name": "Aeropuerto Internacional Manuel Crescencio Rejon",
        "city": "Merida",
        "state_province": "Yucatan",
        "country": "Mexico"
    }
]