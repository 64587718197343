import React from 'react';
import { GlareCard } from '../Acernity';
import { ArrowRight } from 'lucide-react';
import { CessnaCitationM2, dasFalc2000s, embraerLeg500 } from '../assets';
import { useTranslation } from 'react-i18next';
import '../../i18n'
export default function AllJetsComponent() {
   const {t,i18n}=useTranslation()
   const jet1 = CessnaCitationM2;
   const jet2 = dasFalc2000s;
   const jet3 = embraerLeg500;
   return (
      <div className="w-[95%] mx-auto h-full border-[1px] border-gray-300 p-7 py-12 rounded-[50px] flex flex-col mt-[7rem] bg-gray-200 justify-center items-center md:gap-2 gap-5">
         <div className="md:text-xl text-sm text-center mt-4"> {t('jetSection.latest')}</div>
         <div className="md:text-3xl text-xl font-extrabold text-center">
          {t('jetSection.safest')} {' '}
         </div>
         <div className="md:text-lg text-xs font-light text-center">
            {t('jetSection.catalog')}
         </div>
         <div className="flex md:flex-row mt-8 flex-col gap-6 md:gap-0 justify-evenly w-full">
            <div className="flex flex-col  justify-center items-center gap-5">
               <GlareCard>
                  <img
                     src={jet1}
                     alt=""
                     className="h-full w-full absolute inset-0 object-cover"
                  />
               </GlareCard>
               <div className="font-bold text-xl">Citation V</div>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
               <GlareCard>
                  <img
                     src={jet2}
                     alt=""
                     className="h-full w-full absolute right-0 object-cover"
                  />
               </GlareCard>
               <div className="font-bold text-xl">Falcon 2000</div>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
               <GlareCard>
                  <img
                     src={jet3}
                     alt=""
                     className="h-full w-full absolute inset-0 object-cover"
                  />
               </GlareCard>
               <div className="font-bold text-xl">Gulfstream G500</div>
            </div>
         </div>
         <div className='bg-mobius-golden rounded-full px-5 py-2 mt-8 flex gap-2 justify-center items-center text-white transition hover:scale-105 cursor-pointer mt-5'>
            <div className='font-semibold text-lg'> {t('jetSection.seeAll')} </div>
            <ArrowRight size={32} />
         </div>
      </div>
   );
}
