import React from 'react';
import { Outlet } from 'react-router-dom';
import {
   Footer,
   NavbarDesktop,
   AuthProvider,
   NavbarMobile,
} from '@/components';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {

  const location = useLocation();

   return (
      <AuthProvider>
         <div className="h-screen w-screen flex flex-col">
            {!(location.pathname==='/book-flight') &&
            <>
           
             <NavbarDesktop />
             <NavbarMobile />
             </>
             }
           
            <Outlet />
            <Footer />
         </div>
         <ToastContainer newestOnTop/>
      </AuthProvider>
   );
};

export default Layout;
