import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function PlaneSelection({ next,setPlaneId,planeId }) {
    const userId = parseInt(localStorage.getItem('USER_ID'), 10);
    const token = localStorage.getItem('ACCESS_TOKEN');
    const [ownerPlanes, setOwnerPlanes] = useState([]);
    const [planeDocs, setPlaneDocs] = useState([]);
    const [ownerId, setOwnerId] = useState('');
    const [planesData, setPlanesData] = useState([]);

      const handlePlaneClick = (id) => {
        setPlaneId(id);

    };
   
useEffect(() => {
       
        const fetchOwnerData = async () => {
            try {
                const response = await axios.get('/api/accounts/owners', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (Array.isArray(response.data)) {
                    const owner = response.data.find(item => (item.user === userId))?.id || null;
                    setOwnerId(owner);
                } else {
                    console.error('Unexpected data format');
                }
            } catch (error) {
                console.error('Error fetching owner data:', error);
            }
        };

        if (token) {
            fetchOwnerData();
        }
    }, [token, userId]);
    // useEffect(()=>{
    //     fetchPlaneDoc(5)
    // })

    useEffect(() => {
        const fetchOwnerPlanes = async () => {
            try {
                const response = await axios.get('/api/fleet/planes/', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setOwnerPlanes(response.data);
                console.log("planes data is",response.data)
            } catch (error) {
                console.error('There was an error fetching the user data!', error);
                toast.error('Could not load user information', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
            }
        };

        if (token) {
            fetchOwnerPlanes();
        }
    }, [token]);

    useEffect(() => {
        const fetchPlaneDocs = async () => {
            try {
                const response = await axios.get('api/media/plane-docs/',
                    {
                       headers: {
                        'Authorization': `Bearer ${token}`
                    }
                    }
                    
                );
                setPlaneDocs(response.data);
                console.log(response.data,'is filtered planes data')
            } catch (error) {
                console.error('There was an error fetching the plane documents!', error);
                toast.error('Could not load plane documents', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
            }
        };

        if (token) {
            fetchPlaneDocs();
        }
    }, [token]);

  

const filterPlaneDocs = useCallback(async (docId) => {
    try {
        if(docId){
        const response = await axios.get(`api/media/public/plane-doc/${docId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;}
    } catch (error) {
        console.error('There was an error fetching the plane documents!', error);
        toast.error('Could not load plane documents', {
            position: 'bottom-right',
            autoClose: 2000,
        });
    }
}, [token]);


useEffect(() => {
    if (ownerId && ownerPlanes.length > 0 && planeDocs.length > 0) {
        const filteredPlanes = ownerPlanes.filter(plane => plane.fleet.owner === ownerId);
        const fetchPlaneData = async () => {
            const result = await Promise.all(
                filteredPlanes.map(async (plane) => {
                    const doc = planeDocs.find(doc => doc.plane === plane.id);
                    const planedoc = doc ? await filterPlaneDocs(doc.id) : null;

                    return {
                        planeId: plane.id,
                        planeModel: plane.planeModel,
                        planereg: plane.registrationNumber,
                        fleetName: plane.fleet.fleetName,
                        document: planedoc ? planedoc.document : null,
                        image: planedoc ? planedoc.image_url : null,
                        docId: doc ? doc.id : null
                    };
                })
            );
            setPlanesData(result);
            console.log(result);
        };

        fetchPlaneData();
    }
}, [ownerId, ownerPlanes, planeDocs, filterPlaneDocs]);


   

    return (
    <div className=" ">
           
                {planesData.length > 0 ? (
                   <div className="grid grid-cols-1 items-center justify-center py-8  sm:grid-cols-2 md:grid-cols-3 gap-4">
                   {planesData.map((plane) => (
                       <div
                           key={plane.id}
                           className="plane-slide w-full flex flex-col items-center p-4 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                           onClick={()=>handlePlaneClick(plane.planeId)}
                       >
                           <div id="plane-doc-container"  onClick={next} className="w-[250px]  h-[250px] bg-gray-100 object-cover rounded-full shadow-lg">
                               {plane.image && (
                                   <img
                                       src={plane.image}
                                       alt={`Plane ${plane.planeId}`}
                                       className="w-full h-full object-cover rounded-full shadow-lg"
                                   />
                               )}
                           </div>
                           <div className="font-bold uppercase text-black mt-2">{plane.planeModel}</div>
                           <div className="text-sm uppercase text-mobius-dark-gray">
                               {plane.planereg}
                           </div>
                       </div>
                   ))}
               </div>
               
                ) : (
                    <div className='text-red-400 text-[1.2rem] text-center mx-auto w-[80%]'>Cannot Find Planes kindly try registering one if this message shows for long time </div>
                )}
            </div>
       
           
        
    );
}

export default PlaneSelection;
