import React from 'react';
import loader from '../assets/plane_loader.png'

const Loading=()=>{
    return(
        
           <div className="bg-white h-[80vh] bg-opacity-50 flex-col py-[5%] z-50 flex justify-center items-center">
               <img src={loader} alt="Loading..." className="mt-[15%] md:w-[100px] w-[100px]" />
               <div className="text-center mt-4 mb-[15%]">
                  
                   <div className="flex justify-center mt-2 space-x-2">
                       <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse "></div>
                       <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse animation-delay-100"></div>
                       <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse animation-delay-200"></div>
                       <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse animation-delay-300 "></div>
                   </div>
               </div>
           </div>
        
    )
}
export default Loading;