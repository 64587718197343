import React, { useEffect, useState, useRef } from 'react';
import InputCard from './InputCard';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '@/components/Loader/Loading';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PassengerDetails = () => {
    const routerLocation = useLocation();
    const { bookPlane, flight, isRound, departureBooked } = routerLocation.state || {};
    const passenger = localStorage.getItem('passengers');
    const passengerNumber = passenger || 0;
    const [loading, setLoading] = useState(false);
    const [sliderVisible, setSliderVisible] = useState(!departureBooked || !isRound);
    const [numPassengers, setNumPassengers] = useState('');
    const [sameAsDeparture, setSameAsDeparture] = useState(false);
    const storedPassengerData = JSON.parse(localStorage.getItem('flightData'));
    const storedFlightId = localStorage.getItem('flightId');
    let navigate = useNavigate();

    const [passengerData, setPassengerData] = useState(Array.from({ length: passengerNumber }, () => ({
        first_name: '',
        last_name: '',
        gender: '',
        birth_date: '',
        email: '',
        phone: ''
    })));

    const sliderRef = useRef(null);

    useEffect(() => {
        // Update the passengerData array if passengerNumber changes
        setPassengerData(prevData =>
            Array.from({ length: passengerNumber }, (_, i) =>
                prevData[i] || {
                    first_name: '',
                    last_name: '',
                    gender: '',
                    birth_date: '',
                    email: '',
                    phone: ''
                }
            )
        );
    }, [passengerNumber]);



    //summary for round trip
    // if (isReturn) {
    //     localStorage.setItem('transactionIdR', response.data.transaction_number.toString());
    //     console.log("is round true");
    // } else {
    //     localStorage.setItem('transactionId', response.data.transaction_number.toString());
    //     console.log("is round false");
    // }

    



    const submitPassengerRound = async () => {

    const baseURL = '/api/flights/flights';
    const flightId = flight; // Outbound flight ID
    const returnFlightId = storedFlightId; // Return flight ID for round trip
    const transactionNumber = 'unique-transaction-id-12345'; // Unique transaction number
    const folioMexPago = 'folio-xyz-67890'; // Unique folio number for payment tracking
        try {
            // Data for registering passengers on a round trip
            const passengerData = {
                "passengers":storedPassengerData,
                "transaction_number": transactionNumber, // Required for payment tracking
                "folio_mex_pago": folioMexPago, // Optional but used for additional payment tracking
                "return_flight_id": returnFlightId, // ID for the return flight to handle round trip
            };
           
            // Make the POST request to register passengers for the round trip
            const response = await axios.post(`${baseURL}/${flightId}/register_passengers/`, passengerData);



            // Handle successful response
            if (response.status === 201) {
                console.log('Passengers registered successfully for round trip!');
                console.log('Transaction IDs:', response.data.transaction_id); // List of transaction IDs for both outbound and return flights
               
                console.log("Passenger API data:", response.data);
                const paymentStatus = 'aprobado'
                const paymentData = {
                    transaction_id: response.data.transaction_id, // Single transaction ID for all passengers and flights
                    payment_status: paymentStatus, // Payment status ('aprobado' or 'rechazado')
                };
               
        
        
                
                toast.success('Passenger registered successfully for round trip.', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });

                setLoading(false);
                navigate('/order-summary', { state: { isRound,flight,transactionIdR:response.data.transaction_id,reservationCode:response.data.reservation_code,sameAsDeparture } });
            } else {
                console.error('Failed to register passengers:', response.data);
            }
        } catch (error) {
            console.error('Error registering passengers for round trip:', error.response ? error.response.data : error.message);
        }
    };

useEffect(()=>{
    console.log(passenger,'is passenger number in passenger details')
})


    const submitPassengerSingle = async ({ passengerData, flight,Return }) => {
        const FLIGHT_ID = flight;
        const TRANSACTION_NUMBER = 'unique-transaction-id-12345';
        const FOLIO_MEX_PAGO = 'folio-xyz-67890';
        const registerPassengerUrl = `/api/flights/flights/${FLIGHT_ID}/register_passengers/`;

        const data = {
            "passengers": passengerData,
            "transaction_number": TRANSACTION_NUMBER,
            "folio_mex_pago": FOLIO_MEX_PAGO,
        };

        try {
            const response = await axios.post(registerPassengerUrl, data);
            if (response.status === 201) {
                console.log("Passengers registered successfully!");
                console.log("Passenger API data:", response.data);

               
                toast.success('Passenger registered successfully.', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
                console.log('Return is ',Return)
               if(Return){
                setLoading(false);
                navigate('/order-summary', { state: { isRound,flight,transactionIdR:response.data.transaction_id,reservationCode:response.data.reservation_code,sameAsDeparture} });
                console.log('if part of return is working')
               }
               else{

               localStorage.setItem('transactionId', response.data.transaction_id.toString() );
               localStorage.setItem('reservationCode', response.data.reservation_code );
               console.log('transactionId is set to',response.data.transaction_id)
               }
               

            } else {
                console.log("failed to register passenger")
                throw new Error("Failed to register passengers");
            }
        } catch (error) {
            console.log("An error occurred:", error.response ? error.response.data : error.message);
            setLoading(false); // Ensure loading is stopped in case of error
        }
    };

    useEffect(() => {
        console.log("this is isround in passenger details ", isRound)
    })

    const handlePassenger = () => {
        setLoading(true);

        if (isRound && departureBooked) {
            submitPassengerSingle({ passengerData: passengerData, flight: flight,Return:false });
            submitPassengerSingle({ passengerData: storedPassengerData, flight: storedFlightId,Return:true });

            localStorage.setItem('departureBooked', false);
            console.log('double passenger submission worked');
        } else if (isRound && !departureBooked) {
            localStorage.setItem('flightData', JSON.stringify(passengerData));
            localStorage.setItem('departureBooked', true);
            localStorage.setItem('flightId', flight);
            navigate('/book-flight', { state: { isRound } });
            console.log('else if part worked');
        } else {
            submitPassengerSingle({ passengerData: passengerData, flight: flight,Return:true });
            console.log('single passenger submission worked');
            localStorage.setItem('departureBooked', false);
        }
    };

    const handleContinue = () => {
        setSliderVisible(true);
        setPassengerData(Array.from({ length: numPassengers }, () => ({
            first_name: '',
            last_name: '',
            gender: '',
            birth_date: '',
            email: '',
            phone: ''
        })));

        if (sameAsDeparture) {
            setLoading(true);

            submitPassengerRound(storedPassengerData, storedFlightId);
            localStorage.setItem('departureBooked', false);


        }
    };

    const NextArrow = ({ onClick }) => (
        <div className='hidden' onClick={onClick}>
            <i className="fas fa-chevron-right"></i>
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className='hidden' onClick={onClick}>
            <i className="fas fa-chevron-left"></i>
        </div>
    );

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const next = () => {
        sliderRef.current.slickNext();
    };

    const updatePassengerData = (index, data) => {
        return new Promise((resolve, reject) => {
            try {
                const updatedData = [...passengerData];
                updatedData[index] = data;
                setPassengerData(updatedData);
                resolve(); // Resolve the promise after the state is updated
            } catch (error) {
                reject(error); // Reject the promise if there is an error
            }
        });
    };

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div className='flex pt-16 md:pt-4 flex-col'>
                    <p className='text-black font-bold text-3xl text-center m-6'>Passenger Information</p>

                    {sliderVisible ? (
                        <div className='flex flex-col  items-center mt-8 justify-center'>
                            <Slider {...settings} className='w-[90%] mx-auto mt-4' ref={sliderRef}>
                                {Array.from({ length: passengerNumber }).map((_, index) => (
                                    <div key={index}>
                                        <div className='text-2xl text-center font-bold'>
                                            Enter details for Passenger No. {index + 1}
                                        </div>
                                        <InputCard
                                            next={next}
                                            passengers={passengerNumber}
                                            finalize={handlePassenger}
                                            index={index}
                                            updatePassengerData={updatePassengerData}
                                            passengerData={passengerData[index]}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                        bookPlane && isRound && departureBooked ? (
                            <div className='flex h-[70vh] flex-col gap-4 items-center'>
                                <p className='text-lg font-semibold mb-4'>Enter the number of passengers:</p>
                                <input
                                    type="number"
                                    value={numPassengers}
                                    onChange={(e) => setNumPassengers(parseInt(e.target.value, 10))}
                                    className='p-2 border border-gray-300 rounded-md mb-4'
                                    min={1}
                                />
                                <label className='flex items-center gap-2 mb-4'>
                                    <input
                                        type="checkbox"
                                        checked={sameAsDeparture}
                                        className=''
                                        onChange={(e) => setSameAsDeparture(e.target.checked)}
                                    />
                                    Passengers same as departure flight?
                                </label>
                                <button
                                    onClick={handleContinue}
                                    className='px-4 py-2 bg-white text-black border-2 border-mobius-golden hover:bg-mobius-golden hover:text-white rounded-md'
                                >
                                    Continue
                                </button>
                            </div>
                        ) : (
                            <div>
                                <p className='text-black text-center text-lg mx-6 my-2'>
                                    Please write the passenger information as displayed on official ID
                                </p>
                                <div className='flex gap-4'>
                                    <InputCard
                                        next={next}
                                        passengers={0}
                                        finalize={handlePassenger}
                                        index={0}
                                        passengerData={{}}
                                        updatePassengerData={updatePassengerData}
                                    />
                                </div>
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default PassengerDetails;
