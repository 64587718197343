import React, { useState, useEffect } from 'react';
import { Combobox } from '@headlessui/react';
import axios from 'axios';

const CrewSelection = ({selectedCrewIds,setSelectedCrewIds}) => {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [CrewData, setCrewData] = useState([]);
  const [selectedCrew, setSelectedCrew] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const fetchCrewData = async () => {
      try {
        const response = await axios.get('api/crew/crew/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCrewData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('There was an error fetching crew data!', error);
      }
    };
    if (token) {
      fetchCrewData();
    }
  }, [token]);
  useEffect(() => {
    console.log("the ids for crew is",selectedCrewIds)
  })
  const handleSelectCrew = (crew) => {
    if (selectedCrew.length < 3) {
      if (!selectedCrew.find((member) => member.id === crew.id)) {
        setSelectedCrew([...selectedCrew, crew]);
        setSelectedCrewIds([...selectedCrewIds, crew.id]); // Add crew ID to the array
      }
    } else {
      alert('You can only select up to 3 crew members.');
    }
  };

  const handleRemoveCrew = (id) => {
    setSelectedCrew(selectedCrew.filter((member) => member.id !== id));
    setSelectedCrewIds(selectedCrewIds.filter((crewId) => crewId !== id)); // Remove crew ID from the array
  };

  return (
    <div className="flex h-[60vh] flex-col mt-8 justify-center items-center md:px-28 text-center">
      <div className="md:mb-0 mb-5">
        <div className="font-bold md:text-5xl mt-8 text-3xl">Crew Selection</div>
        <div className="font-light px-4 text-gray-800 md:mt-10 mt-2 mb-8">
          Select Crew members for your flight
        </div>
      </div>
      <div className="mb-4 relative w-[90%] md:w-1/2">
        <Combobox onChange={handleSelectCrew}>
          <Combobox.Input
            className="px-4 py-2 border-0 border-b-2 text-black focus:outline-none focus:border-b-2 w-full"
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Select a Crew Member"
          />
          <Combobox.Options className="mt-1 w-[100%] absolute bg-gray-200 z-[999] border rounded-md max-h-60 overflow-auto">
            {CrewData.filter((crew) =>
              crew.name.toLowerCase().includes(query.toLowerCase())
            ).map((crew) => (
              <Combobox.Option
                key={crew.id}
                value={crew}
                className={({ active }) =>
                  `px-8 py-2 cursor-pointer flex justify-between  ${
                    active ? 'bg-mobius-golden text-white' : ''
                  }`
                }
                onClick={() => handleSelectCrew(crew)}
              >
                
               <span className={({ active }) =>
                  `px-4 py-2 cursor-pointer  ${
                    active ? 'bg-mobius-golden text-white' : ''
                  }`
                }>{crew.name}</span>
                 <span className={({ active }) =>
                  `px-4 py-2 cursor-pointer  ${
                    active ? 'bg-mobius-golden text-white' : ''
                  }`
                }>{crew.role}</span>
              
                
                
               
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox>
      </div>
      <div className="mt-4 w-[90%] md:w-1/2">
        {selectedCrew.map((crew) => (
          <div
            key={crew.id}
            className="grid md:grid-cols-4 gap-4 md:gap-0 grid-cols-2 justify-center items-center bg-gray-100 p-2 mb-4 rounded-md shadow-md"
          >
            <div className='flex gap-2'>
              <p className="font-bold ">{crew.name}</p>
              <p className="font-bold ">{crew.surname}</p>
            </div>
            <div className="text-sm text-right md:text-left font-bold text-gray-600"> Role: {crew.role} </div>
            <div className="text-sm font-bold text-gray-600 text-nowrap"> License No.: {crew.licenseNumber} </div>

            <div className=''>
              <p className='cursor-pointer text-right text-sm font-bold text-gray-600 text-nowrap hover:text-red-500' onClick={() => handleRemoveCrew(crew.id)}> Delete</p>
            </div>
          </div>
        ))}
        {selectedCrew.length < 1 && (
          <p className="text-red-500 mt-2">
            Please select at least 1 crew members.
          </p>
        )}
      </div>
    </div>
  );
};

export default CrewSelection;
