import React from 'react';
import { RegisterSection } from '@/components';

const Register = () => {
   return (
      <div className="backg-login py-0 md:py-16  w-full  flex justify-center items-center">
         <RegisterSection />
      </div>
   );
};

export default Register;
