import React from 'react';

const ChevronLeft = (props) => {
   const { className, onClick } = props;
   return (
      <svg
         onClick={onClick}
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke-width="1.5"
         stroke="currentColor"
         className={className}
      >
         <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
         />
      </svg>
   );
};

export default ChevronLeft;
