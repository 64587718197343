import React, { useState } from 'react';
import { UserRound } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '..';
import { toast } from 'react-toastify';

const SetPassword = () => {
   const [email, setEmail] = useState('');
  
   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   
   const handleSubmit = async (event) => {
      event.preventDefault();
      console.log('Logging in with:', email);
      try {
         const response = await axios.post('/api/accounts/login/', {
            email: email,
         });
         if (response.status === 200) {
            console.log('Login successful:', response.data);
            localStorage.setItem('ACCESS_TOKEN', response.data.access);
            localStorage.setItem('USER_ID', response.data.id);
   
            localStorage.setItem('isOwner', response.data.owner);
          
            console.log('User:', response.data.email, response.data.id, response.data.owner);
            toast.success('Inicio de sesión exitoso', {
               position: 'bottom-right',
               autoClose: 2500,
            });
         } else {
            toast.error('Inicio de sesión fallido', {
               position: 'bottom-right',
               autoClose: 2500,
            });
         }
      } catch (error) {
         toast.error('Inicio de sesión fallido', {
            position: 'bottom-right',
            autoClose: 2500,
         });
      }
   };
   

   return (
      <div className="bg-mobuis-gray rounded-2xl md:w-[40%] w-full min-h-[700px] flex flex-col md:pt-[2rem] md:py-8 pt-[14rem] pb-[5rem]  shadow-md space-y-10 items-center justify-center">
         <div className="flex flex-col items-center space-y-8">
            <div className="bg-mobius-golden rounded-full size-20 flex justify-center items-center">
               <UserRound size={64} color="#ffffff" />
            </div>
            <div className="text-2xl">Bienvenido</div>
            <div className="flex w-48 justify-center items-center relative">
               <div className="p-2 bg-mobius-golden rounded-full absolute left-0"></div>
               <div className="w-48 bg-mobius-golden rounded-lg h-1.5"></div>
               <div className="p-2 bg-mobius-golden rounded-full absolute right-0"></div>
            </div>
         </div>

         <div className='text-center w-[60%] text-md'>
         Escribe tu correo para poder
         realizar el cambio de tu contraseña
         </div>
        
         <form className="w-[80%] flex flex-col space-y-4 mt-8 items-center" onSubmit={handleSubmit}>
            <div className="flex flex-col w-full space-y-1">
               <div>
                 
               </div>
               <input
                  type="email"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={email}
                  onChange={handleEmailChange}
               />
            </div>
            
            <div className="flex w-full flex-col gap-12 pt-5 mt-[7rem] justify-center items-center">
              
               <button
                  type="submit"
                  className="bg-mobius-golden p-2 rounded-full text-white text-xl w-2/3 transition duration-200 hover:transform hover:scale-105"
               >
                 Enviar
               </button>
            </div>
         </form>
     
       
      </div>
   );
};

export default SetPassword;
