import React from 'react';

export default function SettingsClient() {
   return (
      <div className="h-full w-full flex flex-col gap-5 text-black">
         <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">Idioma Preferido</div>
            <div className="flex gap-5">
               <span>Español </span>
               <button className="text-mobius-golden">Cambiar</button>
            </div>
         </div>
         <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">Moneda Preferida</div>
            <div className="flex gap-5">
               <span>Peso Mexicano </span>
               <button className="text-mobius-golden">Cambiar</button>
            </div>
         </div>
         <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">Zona Horaria</div>
            <div className="flex gap-5">
               <span>
                  CT
               </span>
               <button className="text-mobius-golden">Cambiar</button>
            </div>
         </div>
      </div>
   );
}
