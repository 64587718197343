import React from 'react';

function InformacionGeneral({
   name,
   first_surname,
   second_surname,
   email,
   date_of_birth,
   telephone,
   owner,
}) {
   console.log(
      'InformacionGeneral:',
      name,
      first_surname,
      second_surname,
      email,
      date_of_birth,
      telephone,
      owner,
   );
   return (
      <div className="w-full h-full flex flex-col space-y-2">
         <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
               <h3 className="font-semibold">Name</h3>
               <span>{name}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">First Surname</h3>
               <span>{first_surname}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">Second Surname</h3>
               <span>{second_surname}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">Email</h3>
               <span>{email}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">Date of Birth</h3>
               <span>{date_of_birth}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">Telephone</h3>
               <span>{telephone}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">Owner</h3>
               <span>{owner ? 'Yes' : 'No'}</span>
            </div>
         </div>
      </div>
   );
}

export default InformacionGeneral;
