import React, { useRef, useState, useEffect } from 'react';
import { UploadFileIcon, MailIcon, ChevronLeft, ChevronRight } from '@/components';
import PreviousNext from './PreviousNext';
import PlaneSelection from './PlaneSelection';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AirportSelection from '../OwnerReg/AirportSelection';
import { AirportData } from '../../components/data/AirportData.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CrewSelection from './CrewSelection';
import { toast } from 'react-toastify';
import Loading from '@/components/Loader/Loading';
import DetailsInput from './DetailsInput';
const FlightReg = () => {

   let sliderRef = useRef(null);
   let innersliderRef = useRef(null);
   const [activeSlide, setActiveSlide] = useState(0);
   const [selectedBase, setSelectedBase] = useState(null);
   const [selectedBase2, setSelectedBase2] = useState(null);
   const [planeSeats, setplaneSeats] = useState(null);

   const [loading, setLoading] = useState(false); // Loading state


   // const totalSlides = 5;
   const [selectedState, setSelectedState] = useState('');
   const [query, setQuery] = useState('');
   const [selectedState2, setSelectedState2] = useState('');
   const [planeId, setPlaneId] = useState('')
   const [departure_date, setDepartureDate] = useState('')
   const [arrival_date, setArrivalDate] = useState('')
   const [departure_time, setDepartureTime] = useState('')
   const [arrival_time, setArrivalTime] = useState('')
   const [airline, setAirline] = useState('');
   const [flightNo, setFlightNo] = useState('');
   const [selectedCrewIds, setSelectedCrewIds] = useState([]);
   const [flightDocument, setFlightDocument] = useState(null);
   const [documentName, setDocumentName] = useState('');
   const [confirmation, setConfirmation] = useState('');

   let navigate = useNavigate();
   const [price, setPrice] = useState(''); // Initial price, adjust as needed

   const cities = [...new Set(AirportData.map(data => data.city))]; // Extract unique cities
   const filteredCities = query === ''
   ? cities
   : cities.filter(city =>
      city.toLowerCase().includes(query.toLowerCase())
   ); 
   const ClearData = () => {
      setPlaneId('')
      setSelectedBase(null)
      setSelectedBase2(null)
      setSelectedState('')
      setSelectedState2('')
      setArrivalDate('')
      setArrivalTime('')
      setDepartureDate('')
      setDepartureTime('')
      setPrice('')
      setSelectedCrewIds([])
      setFlightDocument(null)
      setDocumentName('')
      setFlightNo('')
      setAirline('')
   };
  

   const handleSelectBase = (index) => {
      setSelectedBase(index);
      console.log(selectedBase)
   };
   const handleSelectBase2 = (index) => {
      setSelectedBase2(index);
      console.log(selectedBase)
   };
   useEffect(() => {
      console.log(selectedBase)
   })
   const filteredAirports = selectedState
      ? AirportData.filter(data => data.city === selectedState)
      : [];

   const filteredAirports2 = selectedState2
      ? AirportData.filter(data => data.city === selectedState2)
      : [];

   const token = localStorage.getItem('ACCESS_TOKEN');

   const navigateHome = () => {
      navigate('/')
      setConfirmation(false)
   }
   const arrowSytle2 =
      'absolute top-1/2 p-1 px-2 rounded-full h-[420px] w-[40px] md:h-[45px] md:w-[45px] z-10 text-mobius-dark-gray ';

   function SampleNextArrow2(props) {
      const { onClick } = props;
      return (
         <ChevronRight
            onClick={onClick}
            className={`${arrowSytle2}transition-opacity duration-300 ease-in-out opacity-100 md:-right-14 right-1 cursor-pointer`}
         />
      );
   }

   function SamplePrevArrow2(props) {
      const { onClick } = props;
      return (
         <ChevronLeft
            onClick={onClick}
            className={`${arrowSytle2}transition-opacity duration-300 ease-in-out opacity-100 md:-left-14 left-1 cursor-pointer`}
         />
      );
   }



   const getPlane = async () => {
      if (planeId) {
         try {
            const response = await axios.get(`/api/fleet/planes/${planeId}`,
               {
                  headers: {
                     'Authorization': `Bearer ${token}`
                  }
               });
            console.log(" selected plane Data is", response.data)
            setplaneSeats(response.data.capacity)
         } catch (error) {
            console.error('There was an error fetching the user data!', error);

         }
      }

   };



   const uploadFlightDocs = async (flight_id) => {
      const formData = new FormData();
      formData.append('flight', flight_id); // Plane ID
      // Append the single image file only if it is provided
      if (flightDocument) {
         formData.append('document', flightDocument); // Document file
      }
      try {
         const response = await axios.post('api/media/flight-plans/', formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               'Authorization': `Bearer ${token}`, // Replace with your actual token
            },
         });
         if (response.status === 201) {
            console.log('Plane docs upload successful:', response.data);


            toast.success('Flight documents uploaded Successfully.', {
               position: 'bottom-right',
               autoClose: 2000,
            });
            toast.success('Your Flight data is uploaded successfully.', {
               position: 'bottom-right',
               autoClose: 2000,
            });
            ClearData()

         }

      } catch (error) {
         console.error('Error uploading plane docs:', error.response ? error.response.data : error.message);
      }
   };

   const handleDocumentUpload = (e) => {
      const file = e.target.files[0];
      setFlightDocument(file);
      setDocumentName(file.name);
   };


   const postFlight = async () => {
      if (!planeId) {
         toast.error('Plane Selection is required.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!selectedBase) {
         toast.error('Please choose your origin airport to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!selectedBase2) {
         toast.error('Please choose your destination airport to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!departure_date) {
         toast.error('Please choose Departure Date of your flight to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!departure_time) {
         toast.error('Please choose Departure Time of your flight to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!arrival_date) {
         toast.error('Please choose Arrival Date of your flight to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!arrival_time) {
         toast.error('Please choose Arrival Time of your flight to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!flightNo) {
         toast.error('Please Provide Flight Number  to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!airline) {
         toast.error('Please Provide your Airline Flight to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      if (!flightDocument) {
         toast.error('Please Provide a complete Flight Document  to continue.', {
            position: 'bottom-right',
            autoClose: 2500,
         });
         return;
      }
      setLoading(true); // Start loader
      const seat_price = price / planeSeats
      try {
         const response = await axios.post('api/flights/flights/', {
            flight_number: flightNo,
            origin: selectedBase,
            destination: selectedBase2,
            departure_date: departure_date,
            departure_time: departure_time,
            arrival_time: arrival_time,
            arrival_date: arrival_date,
            plane: planeId,
            seat_price: seat_price,
            whole_plane_price: price,
            crew_members: selectedCrewIds,
            status: "On Time",
            airline: airline,
         },
            {
               headers: {
                  'Authorization': `Bearer ${token}`
               }
            });
         if (response.status === 201) {
            console.log("flight data is", response.data)
            uploadFlightDocs(response.data.id)


         }

      } catch (error) {
         console.error('There was an error fetching the user data!', error);

      }
      setConfirmation(true)
      setLoading(false); // Stop loader


   };


   useEffect(() => {
      // console.log("plane id is",planeId)
      // console.log("origin city id",selectedBase)
      // console.log("destination city id",selectedBase2)
      // console.log("departure date ",departure_date)
      // console.log("departure time ",departure_time)
      // console.log("arrival time ",arrival_time)
      //  console.log("arrival date ",arrival_date)
      // console.log("flight number ",flightNo)
      // console.log("airline",airline)
      // console.log("crew members", selectedCrewIds)
      // console.log("price of plane",price)

      // console.log("price of seat",seat_price)

   })
   const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,

      swipe: false,
      beforeChange: (current, next) => {
         setActiveSlide(next);
         console.log(activeSlide);
      },
   };
   const settings2 = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,
      arrows: false,
      fade: true,
      nextArrow: <SampleNextArrow2 />,
      prevArrow: <SamplePrevArrow2 />,
      swipe: false,
   };
   const pricenext = () => {
      sliderRef.slickNext();
      getPlane()
   };

   const next = () => {
      sliderRef.slickNext();

   };
   const previous = () => {
      sliderRef.slickPrev();
   };
   const innernext = () => {
      innersliderRef.slickNext();
   };
   const innerprevious = () => {
      innersliderRef.slickPrev();
   };

   return (
      <div className=''>
         <Slider
            ref={(slider) => {
               sliderRef = slider;
            }}
            {...settings}
            className=""
         >
            <div key={1}>
               <div className="  flex h-[100vh] overflow-y-auto flex-col w-screen items-center mt-8 justify-evenly md:py-10 py-20">
                  <div className="flex flex-col gap-6">
                     <div className="md:text-4xl text-2xl md:text-left text-center font-bold">Choose your Aircraft</div>
                     <div className=" md:text-xl md:w-full w-[80%] mx-auto text-center  text-lg font-light">
                        Select the aircraft you want to offer
                     </div>
                  </div>
                  <div className="flex justify-evenly my-10  items-center w-full">
                     <PlaneSelection next={next} planeId={planeId} setPlaneId={setPlaneId} />
                  </div>
               </div>
            </div>
            <div key={2} className=''>
               <div className="h-[100vh] flex flex-col w-screen items-center justify-center md:py-0 ">
                  <div className="flex flex-col">
                     <div className="md:text-4xl text-3xl pt-12 font-bold">
                        Enter flight details?
                     </div>
                  </div>
                  <div className="innerslider w-full  flex flex-col">

                     <Slider
                        {...settings2}
                        ref={(slider) => {
                           innersliderRef = slider;
                        }}
                        className="h-full"
                     >
                        <div key={1} className="md:w-1/2  w-full mx-auto mt-6 flex flex-col justify-center items-center md:gap-4 gap-2" >

                           <AirportSelection
                              selectedCity={selectedState}
                              setSelectedCity={setSelectedState}
                              filteredCities={filteredCities}
                              filteredAirports={filteredAirports}
                              handleSelectBase={handleSelectBase}
                              selectedBase={selectedBase}
                              heading={'Origin'}
                              subheading={'Select Airport from your origin city'}
                              height={false}
                              cities={cities}
                           />

                           <PreviousNext innernext={innernext} innerprevious={innerprevious} />
                        </div>
                        <div key={2} className="md:w-1/2 w-3/4 mx-auto mt-6 flex flex-col justify-center items-center md:gap-4 gap-2" >

                           <AirportSelection
                              selectedCity={selectedState2}
                              setSelectedCity={setSelectedState2}
                              filteredCities={filteredCities}
                              filteredAirports={filteredAirports2}
                              handleSelectBase={handleSelectBase2}
                              selectedBase={selectedBase2}
                              heading={'Destination'}
                              subheading={'Select Airport from your destination city'}
                              height={false}
                              cities={cities}
                           />
                           <PreviousNext innernext={innernext} innerprevious={innerprevious} />

                        </div>
                        <div key={3} className="h-full">
                           <div className="flex  flex-col items-center justify-center h-full ">
                              <DetailsInput first_heading='Departure Date' first_desc='Enter your flight departure date' second_heading='Departure Time' second_desc='Enter departure time of your flight'
                                 value_1={departure_date} value_2={departure_time} onChange_1={setDepartureDate} onChange_2={setDepartureTime} type_1='date' type_2='time'
                              />
                              <PreviousNext innernext={innernext} innerprevious={innerprevious} />
                           </div>
                        </div>
                        <div key={4} className="h-full">
                           <div className="flex flex-col gap-6 items-center justify-center md:h-full">
                              <DetailsInput first_heading='Arrival Date' first_desc='Enter your flight arrival date' second_heading='Arrival Time' second_desc='Enter Arrival time of your flight'
                                 value_1={arrival_date} value_2={arrival_time} onChange_1={setArrivalDate} onChange_2={setArrivalTime} type_1='date' type_2='time'
                              />
                              <PreviousNext innernext={innernext} innerprevious={innerprevious} />
                           </div>
                        </div>

                        <div key={5} className="h-full">
                           <DetailsInput first_heading={'Flight Number'} first_desc={'Enter flight number'} value_1={flightNo} onChange_1={setFlightNo}
                              second_desc={'Enter the name of airline'} second_heading={'Airline'} value_2={airline} onChange_2={setAirline} type_1='text' type_2='text' />
                           <PreviousNext innernext={next} innerprevious={innerprevious} />
                        </div>

                     </Slider>
                  </div>
               </div>
            </div>
            <div key={3} className="h-full">
               <CrewSelection selectedCrewIds={selectedCrewIds} setSelectedCrewIds={setSelectedCrewIds} />
               <PreviousNext innernext={next} innerprevious={previous} />
            </div>
            <div key={4}>
               <div className=" h-[100vh] flex flex-col w-screen items-center justify-evenly">
                  <div className="flex flex-col mt-8 gap-6">
                     <div className=" md:text-4xl text-2xl text-center md:text-left font-bold">
                        Enter the total price of the flight
                     </div>
                     <div className=" md:text-xl text-center md:text-left text-lg">
                        Remember that the flight is offered per passenger...{' '}
                     </div>
                  </div>
                  <div className="flex flex-col items-center justify-center p-4 md:w-1/2 w-full">

                     <div className="relative md:w-4/5 w-3/4 flex md:flex-row flex-col justify-center gap-6 items-center">
                     <div className='text-[2rem] font-bold'>
                        $
                     </div>
                        <input
                           type="number"
                           value={price}
                           placeholder='Price in MXN'
                           onChange={(e) => setPrice(e.target.value)}
                           className="w-full bg-mobius-gray px-6 border-2 border-mobius-golden outline-mobius-golden  py-2 max-w-[400px] text-[2rem] rounded-lg appearance-none cursor-pointer  pb-0 mb-0"
                        />
                         <div className='text-[2rem] font-bold'>
                        MXN
                     </div>

                     </div>
                  </div>
                  <PreviousNext innernext={pricenext} innerprevious={previous} />
                 
               </div>
            </div>
            <div key={5}>
               {loading ? (
                    <Loading/>
               ) : (
                  confirmation ? (
                     <div className="flex h-[100vh] flex-col mt-16 w-screen items-center justify-center">
                        <div>
                           <div className="md:text-5xl text-center text-3xl font-bold mb-6">
                              We are reviewing your request
                           </div>
                           <div className="md:text-xl text-center text-base">
                              We will send you an email to your registered email address with
                              the confirmation of the empty leg.
                           </div>
                        </div>
                        <MailIcon className="md:size-56 size-36 text-mobius-golden" />
                        <div className='border-2 border-mobius-golden cursor-pointer mx-auto mb-[5rem] text-center w-[200px] transition duration-300 ease-in-out transform hover:scale-105 p-3 hover:bg-mobius-golden hover:text-white mt-6 rounded-md' onClick={navigateHome}>
                           Continue
                        </div>
                     </div>
                  ) : (
                     <>
                        <div className="h-[100vh] min-h-[85vh] flex flex-col justify-evenly items-center md:py-11 px-5 md:text-center">
                           <div className="">
                              <div className="font-bold md:text-5xl text-center  text-3xl mb-5">
                                 Upload your flight Plan
                              </div>
                              <div className="md:text-2xl text-center mb-5">
                                 Add complete flight plan document
                              </div>
                           </div>
                           <div className="flex justify-center items-center gap-10">
                              <div className="outline outline-3 outline-mobius-golden rounded-xl flex flex-col justify-evenly items-center md:py-6 md:px-10 py-3 px-5 cursor-pointer gap-5">
                                 <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.jpg,.png"
                                    onChange={handleDocumentUpload}
                                    className="hidden"
                                    id="flight-document-upload"
                                 />
                                 <label htmlFor="flight-document-upload" className="cursor-pointer">
                                    <UploadFileIcon className="md:w-16 md:h-16 w-12 h-12" />
                                    <div className="md:text-xl">Drag and drop or click to upload</div>
                                 </label>
                              </div>
                           </div>
                           {documentName && (
                              <div className="mt-5 text-center">
                                 <p className="text-xl">Uploaded Document: {documentName}</p>
                              </div>
                           )}
                        </div>
                        <div className='flex flex-col gap-6 items-center justify-center'>
                           <button
                              onClick={previous}
                              className="bg-mobuis-gray mx-auto hover:bg-mobius-golden hover:text-white py-2 text-mobius-golden rounded-lg md:w-[150px] w-[100px] transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                           >
                              Go Back
                           </button>
                           <div className='border-2 border-mobius-golden cursor-pointer mx-auto mb-[5rem] text-center w-[250px] transition duration-300 ease-in-out transform hover:scale-105 p-3 hover:bg-mobius-golden hover:text-white rounded-md' onClick={postFlight}>
                              Register Flight
                           </div>
                        </div>
                     </>
                  )
               )}
            </div>
         </Slider>
      </div>
   );
};

export default FlightReg;
