
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { UserIcon, Plug, Tv, WifiIcon, PlaneIcon } from 'lucide-react'
const FlightComponent = ({ departure, origin, availableSeats, arrival,flightID, destination, seat_price, plane_price,passengerNumber,isRound,departureBooked }) => {
    const [flightTime, setFlightTime] = useState('');
   const passenger=passengerNumber




    let navigate =useNavigate()
    

    useEffect(()=>{
        console.log('the passenger in flight component is',passenger)
        console.log('is round in flight component is',isRound)
       
    })
    const navigatePassenger=()=>{
        const flightId=flightID
        console.log(flightId)

        navigate('/passenger-details',{ state: { bookPlane:true,flight:flightId,passenger,isRound,departureBooked } })
    }
    const navigatePlane=()=>{
        const flightId=flightID
        navigate('/passenger-details',{ state: { bookPlane:false,flight:flightId,isRound,departureBooked} })
    }
    useEffect(() => {


        if (departure && arrival) {
            const [hours1, minutes1] = departure.split(":").map(Number);
            const [hours2, minutes2] = arrival.split(":").map(Number);

            const totalMinutes1 = hours1 * 60 + minutes1;
            const totalMinutes2 = hours2 * 60 + minutes2;

            const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

            const diffHours = Math.floor(differenceInMinutes / 60);
            const diffMinutes = differenceInMinutes % 60;

            const calculatedTime = `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;
            setFlightTime(calculatedTime)

        }
    }, [setFlightTime, departure, arrival])

    return (
        <div className="flex items-center gap-[3rem] flex-wrap md:flex-nowrap border-2 border-mobius-golden m-6 py-8 rounded-md  px-16 width-full md:justify-between justify-center ">
            {/* first entry */}
            <div className="text-center">
                <p className="text-xl font-bold">{departure}</p>
                <p className="text-gray-500">{origin}</p>
                <div className='flex gap-2 justify-center'>
                    <WifiIcon />
                    <Tv />
                    <Plug />
                </div>

            </div>
            {/* second entry */}
            <div className="flex flex-col gap-2 items-center">
                {/* duration bar */}
                <div className="flex md:w-[350px] w-[150px] justify-center items-center relative">
                    <div className="p-2 bg-mobius-golden rounded-full absolute left-0"></div>
                    <div className="w-[350px] bg-mobius-golden rounded-lg h-1.5"></div>
                    <div className="p-2 bg-mobius-golden rounded-full absolute right-0"></div>
                </div>
                <p className="text-gray-500 font-bold">{flightTime}m</p>
                <div className='flex justify-between items-center'>
                    <UserIcon />

                    <div className="text-gray-500 text-center  font-bold">  {availableSeats} seats available</div>
                </div>

            </div>
            {/* third entry */}
            <div className="flex items-center space-x-2">
                <div className="text-center flex flex-col items-start justify-start">
                    <p className="text-xl font-bold">{arrival}</p>
                    <div className='flex gap-2 '>
                        <p className="text-gray-500">{destination}</p>
                        <p className='underline cursor-pointer hover:text-mobius-golden'>more details</p>
                    </div>
                </div>
            </div>

            {/* fourth entry */}
            <div className='flex cursor-pointer transform hover:scale-110 transition-transform duration-300   items-center justify-center gap-2' onClick={navigatePassenger}>
                <UserIcon size={25} />
                <div className='rounded-xl p-2 font-bold bg-gray-200'>
                    ${seat_price}
                </div>
            </div>
            {/* fifth entry */}
            <div className='flex cursor-pointer transform hover:scale-110 transition-transform duration-300  items-center justify-center gap-2' onClick={navigatePlane}>
                <PlaneIcon size={25} />
                <div className='rounded-xl p-2 font-bold bg-gray-200'>
                    ${plane_price}
                </div>
            </div>

        </div>

    )
}
export default FlightComponent