import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";
const OrderConfirmation=()=>{
    const resCodeR=localStorage.getItem('reservationCode')
    const routerLocation=useLocation()
    const { sameAsDeparture , reservationCode,isRound} = routerLocation.state || {};

    useEffect(()=>{
        console.log(sameAsDeparture,'is condition')
        console.log(reservationCode,'is res code')
    })


    return(
        <div className="p-8 mt-6 min-h-[400px]">
             <div className="text-4xl font-bold text-center w-[70%] mx-auto ">
             Congratulations! Your purchase has been
             processed successfully
             </div>
             {isRound?(
                <>
                 {sameAsDeparture?(
                     <div className="text-lg mt-12 text-center ">
                     Your Reservation code is <span className="text-mobius-golden underline">
                     {reservationCode} </span> 
                  </div>
                 ):(
                    <div className="text-lg mt-12 text-center ">
                Your Reservation code for departure flight is <span className="text-mobius-golden underline">
                {reservationCode} </span> and for return flight is <span className="text-mobius-golden underline">
                {resCodeR} </span>
             </div>
                 )}
              
            </>
             ):(
                <div className="text-lg mt-12 text-center ">
                     Your Reservation code is <span className="text-mobius-golden underline">
                     {reservationCode}   </span> 
                  </div>
             )}
             
             <div className="text-lg w-[50%] mt-6 text-center mx-auto">
             This code has also been sent to your email along with your
             tickets
             </div>
        </div>
    )
}
export default OrderConfirmation