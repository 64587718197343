import React, { useState } from 'react';
import axios from '@/utils/api';
import { UserRound } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const RegisterSection = () => {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [name, setName] = useState('');
   const [surname, setSurname] = useState('');
   const [secondSurname, setSecondSurname] = useState('');
   const [birthday, setBirthday] = useState('');
   const [owner, setOwner] = useState(false);
   const [phone, setPhone] = useState('');
   const [error, setError] = useState('');

   let navigate = useNavigate();

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   const handleOwnerChange = (event) => {
      setOwner(event.target.checked);
   };

   const handlePasswordChange = (event) => {
      setPassword(event.target.value);
   };

   const handleConfirmPasswordChange = (event) => {
      setConfirmPassword(event.target.value);
   };

   const handleNameChange = (event) => {
      setName(event.target.value);
   };

   const handleBirthdayChange = (event) => {
      setBirthday(event.target.value);
   };

   const handlePhoneChange = (event) => {
      setPhone(event.target.value);
   };

   const handleSurnameChange = (event) => {
      setSurname(event.target.value);
   };

   const handleSecondSurnameChange = (event) => {
      setSecondSurname(event.target.value);
   };

   const validatePassword = (password) => {
      return (
         password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password)
      );
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      if (!validatePassword(password)) {
         toast.error(
            'The password must be at least 8 characters, one uppercase letter and one number.',
            {
               position: 'bottom-right',
               autoClose: 5000,
            },
         );
         return;
      }
      if (password !== confirmPassword) {
         toast.error('Passwords do not match', {
            position: 'bottom-right',
            autoClose: 5000,
         });
         return;
      }
      setError('');

      const postData = {
         email,
         password,
         name,
         first_surname: surname,
         second_surname: secondSurname,
         owner:false,
         date_of_birth: birthday,
         telephone: phone,
         passager: false,
         block: false,
      };

      if (owner) {
         // Navigate to the PlaneReg component with postData
         navigate('/plane-reg', { state: postData });
      } else {
         try {
            const response = await axios.post('/api/accounts/users/register/', postData);
            if (response.status === 201) {
               console.log('Registration successful:', response.data);
               toast.success('Successful registration', {
                  position: 'bottom-right',
                  autoClose: 5000,
               });
               navigate('/login');
            } else {
               setError(
                  'Registration failed. Please check your credentials and try again.',
               );
               toast.error('Registration failed', {
                  position: 'bottom-right',
                  autoClose: 5000,
               });
            }
         } catch (error) {
            console.error(
               'Registration error:',
               error.response ? error.response.data : error.message,
            );
            toast.error(`Registration Failed: ${error.response?.data?.message}`, {
               position: 'bottom-right',
               autoClose: 5000,
            });
         }
      }
   };

   return (
      <div className="bg-mobuis-gray md:w-[40%] rounded-2xl w-full h-full flex flex-col space-y-10 items-center justify-center md:py-10 py-20 relative">
         <div className="flex flex-col items-center space-y-2">
            <div className="bg-mobius-golden rounded-full size-20 flex justify-center items-center">
               <UserRound size={64} color="#ffffff" />
            </div>
            <div className="text-2xl"></div>
            <div className="flex w-48 justify-center items-center relative">
               <div className="p-2 bg-mobius-golden rounded-full absolute left-0"></div>
               <div className="w-48 bg-mobius-golden rounded-lg h-1.5"></div>
               <div className="p-2 bg-mobius-golden rounded-full absolute right-0"></div>
            </div>
         </div>

         <form
            className="w-[80%] flex flex-col space-y-8 items-center"
            onSubmit={handleSubmit}
         >
           <div className="flex flex-col w-full space-y-1">
               <label htmlFor="name" className="text-lg">
                  Nombre
               </label>
               <input
                  type="text"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={name}
                  onChange={handleNameChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="surname" className="text-lg">
                  Apellido Paterno
               </label>
               <input
                  type="text"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={surname}
                  onChange={handleSurnameChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="secondSurname" className="text-lg">
                  Apellido Materno
               </label>
               <input
                  type="text"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={secondSurname}
                  onChange={handleSecondSurnameChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="email" className="text-lg">
                  Email
               </label>
               <input
                  type="email"
                  className="py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={email}
                  onChange={handleEmailChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="password" className="text-lg">
                  Contraseña
               </label>
               <input
                  type="password"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={password}
                  onChange={handlePasswordChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="confirmPassword" className="text-lg">
                  Confirmar Contraseña
               </label>
               <input
                  type="password"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="birthday" className="text-lg">
                  Fecha de Nacimiento
               </label>
               <input
                  type="date"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={birthday}
                  onChange={handleBirthdayChange}
               />
            </div>
            <div className="flex flex-col w-full space-y-1">
               <label htmlFor="phone" className="text-lg">
                  Teléfono
               </label>
               <input
                  type="text"
                  className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                  value={phone}
                  onChange={handlePhoneChange}
               />
            </div>
            
            <div className="flex items-center mb-4">
               <div className="group flex relative">
                  <input
                     id="default-checkbox"
                     type="checkbox"
                     checked={owner}
                     onChange={handleOwnerChange}
                     className="w-4 cursor-pointer text-mobius-golden bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="default-checkbox" className="ml-2 text-xl font-medium text-black ">
                    Register as Owner
                  </label>
                  <div className="tooltip opacity-0 invisible md:w-[300px] w-[200px] group-hover:opacity-100 group-hover:visible px-2 py-1 bg-black text-white rounded-md absolute top-10 left-1/2 transform -translate-x-1/2">
                    Mark it only if you have a plane and you want to register that.
                  </div>
               </div>
            </div>

            {error && <div className="text-red-500">{error}</div>}

            <button
               type="submit"
               className="w-full text-white text-base bg-mobius-golden px-5 py-2.5 text-center rounded-full"
            >
               Register
            </button>
            <div className="text-mobius-dark-gray">
               ¿Ya tienes una cuenta?{' '}
               <Link to="/login">
                  <span className="text-blue-600">Inicia Sesión</span>
               </Link>
            </div>
         </form>
      </div>
   );
};

export default RegisterSection;
