import React from 'react';
import { useState } from 'react';
import SearchFlight from './searchFlight';
import AllJetsComponent from './allJetsComp';
import { Hero, HeroMobile } from '..';

export default function SecondSection() {
   const [loading, setLoading] = useState(true);
   let [showSearch, setShowSearch] = useState(false)
   
   return (
      <div className="w-full h-full flex flex-col justify-center items-center p-5 gap-5">
         <SearchFlight setShowSearch={setShowSearch} setLoading={setLoading}  />
         <Hero />
         <HeroMobile />
         <AllJetsComponent />
      </div>
   );
}
