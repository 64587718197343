import { useEffect } from 'react';

export default function GridLoader() {
   useEffect(() => {
      async function getLoader() {
         const { grid } = await import('ldrs');
         grid.register();
      }
      getLoader();
   }, []);
   return (
      <div className='w-screen h-screen bg-black'>
         <l-grid color="#665926" size="150" speed="2.5"></l-grid>
      </div>
   );
}
