import React from 'react';
import { Disclosure } from '@headlessui/react';
import {
   Menu,
   CircleUserIcon,
   X,
   UserRound,
   Plane,
   PlaneTakeoff,
   Eye,
   CircleHelp,
   MessageCircleIcon,
   LogOut,
   BadgeInfo,
   Home,
   Receipt
} from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '..';
import { toast } from 'react-toastify';

function MenuUserMobile() {
   let navigate = useNavigate();

   const { isLoggedIn, user,isOwner } = useAuth();

   const handleLogout = (e) => {
      e.preventDefault();
      navigate('/login');
      localStorage.removeItem('ACCESS_TOKEN');
      toast.success('Cierre de sesión exitoso', {
         position: 'bottom-right',
         autoClose: 2500,
      });
   };

   return (
      <div className="flex items-center relative">
         <Disclosure>
            {({ open }) => (
               <>
                  <Disclosure.Button className="flex items-center gap-2 bg-mobius-golden p-2 rounded-lg">
                     {isLoggedIn ? (
                        <CircleUserIcon size={20} color="white" />
                     ) : null}
                     {open ? (
                        <X size={20} color="white" />
                     ) : (
                        <Menu size={20} color="white" />
                     )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="bg-black absolute top-12 w-screen -right-2 rounded-bl-lg text-base z-20">
                     {({ close }) => (
                        <div>
                           {isLoggedIn ? (
                              <div className="flex flex-col space-y-5 items-end">
                                 <div className="border-b-2 border-mobius-golden text-white text-end flex flex-col items-end space-y-5 py-2">
                                    <div className="px-2 font-bold">
                                       {user}
                                    </div>
                                    <NavLink
                                       to="/"
                                       className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                       onClick={close}
                                    >
                                       <span>Home</span>
                                       <Home size={20} color="white" />
                                    </NavLink>
                                    <NavLink
                                       to="/"
                                       className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                       onClick={close}
                                    >
                                       <span>About Us</span>
                                       <BadgeInfo size={20} color="white" />
                                    </NavLink>
                                 </div>
                                 <NavLink
                                    to="/profile"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Perfil</span>
                                    <UserRound size={20} color="white" />
                                 </NavLink>
                                 {isOwner&&( 
                                    <>

                                    <NavLink
                                    to="/plane-reg"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                     <Plane size={20} color="white" />
                                    <span>Agregar Avión</span>
                                   
                                 </NavLink>

                                 <NavLink
                                 to="/flight-registration"
                                 className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                 onClick={close}
                              >
                                  <PlaneTakeoff size={20} color="white" />
                                 <span>Agendar Vuelo</span>
                                
                              </NavLink>
                              </>
                                 
                              )}
                                 <NavLink
                              to="/book-flight"
                              className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                              onClick={close}
                           >
                              <Receipt size={20} color="white" />
                              <span>Book Flight</span>

                           </NavLink>
                                 
                                 <NavLink
                                    to="/"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Ver vuelos</span>
                                    <Eye size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Offers</span>
                                    <CircleHelp size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Contact Us</span>
                                    <MessageCircleIcon size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/"
                                    className="px-3 pb-4 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={handleLogout}
                                 >
                                    <span>Cerrar Sesion</span>
                                    <LogOut size={20} color="white" />
                                 </NavLink>
                              </div>
                           ) : (
                              <div className="flex flex-col space-y-7 items-end py-5">
                                 <NavLink
                                    to="/"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Inicio</span>
                                    <Home size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/aboutus"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>About Us</span>
                                    <BadgeInfo size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Offers</span>
                                    <CircleHelp size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center"
                                    onClick={close}
                                 >
                                    <span>Contact Us</span>
                                    <MessageCircleIcon size={20} color="white" />
                                 </NavLink>
                                 <NavLink
                                    to="/login"
                                    className="px-3 text-white hover:text-gray-300 flex gap-2 items-center bg-mobius-golden hover:bg-mobius-golden/80 rounded-lg py-2 mr-3"
                                    onClick={close}
                                 >
                                    <span>Iniciar Sesión</span>
                                 </NavLink>
                              </div>
                           )}
                        </div>
                     )}
                  </Disclosure.Panel>
               </>
            )}
         </Disclosure>
      </div>
   );
}

export default MenuUserMobile;
