import { Eye } from "lucide-react";
import React, { useState } from "react";
import { EyeOff } from "lucide-react";

const SetPassword = ({ 
  oldPassword, setOldPassword, 
  newPassword, setNewPassword, 
  confirmPassword, setConfirmPassword, 
  setPassword ,goBack
}) => {
  
  // State variables to manage password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = (setShowFunction, currentState) => {
    setShowFunction(!currentState);
  };

  return (
    <div className='flex  w-full flex-col gap-5'>
      <div>
        <label className='block text-lg mb-2'>Previous Password</label>
        <div className="relative">
          <input
            type={showOldPassword ? "text" : "password"}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className='w-full p-1 border px-3 border-gray-300 rounded-full'
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility(setShowOldPassword, showOldPassword)}
            className="absolute right-3 top-1 text-gray-600"
          >
            {showOldPassword ? <EyeOff/> : <Eye/>}
          </button>
        </div>
      </div>
      
      <div>
        <label className='block text-lg mb-2'>New Password</label>
        <div className="relative">
          <input
            type={showNewPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className='w-full p-1 border px-3 border-gray-300 rounded-full'
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility(setShowNewPassword, showNewPassword)}
            className="absolute right-3 top-1 text-gray-600"
          >
            {showNewPassword ?  <EyeOff/> : <Eye/>}
          </button>
        </div>
      </div>
      
      <div>
        <label className='block text-lg mb-2'>Confirm Password</label>
        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className='w-full p-1 px-3 border border-gray-300 rounded-full'
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility(setShowConfirmPassword, showConfirmPassword)}
            className="absolute right-3 top-1 text-gray-600"
          >
            {showConfirmPassword ?  <EyeOff/> : <Eye/>}
          </button>
        </div>
      </div>
      
      <div className="flex justify-between">
        <button
          onClick={setPassword}
          className='mt-4 p-2 border-2 border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-md'
        >
          Confirm Change
        </button>

        <button
          onClick={goBack}
          className='mt-4 p-2 border-2 border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-md'
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default SetPassword;
