import React, { useEffect, useRef } from 'react';
import { motion, useAnimate } from 'framer-motion';
import { mountainsG, takeOffJet } from '../assets';

export default function HeroMobile() {
   const ref = useRef(null);

   const mountains = mountainsG;
   const jet1 = takeOffJet;

   const [scope, animate] = useAnimate();

   const [scopeImage, animateImage] = useAnimate();

   const [scopeJet, animateJet] = useAnimate();

   const [scopeText, animateText] = useAnimate();

   async function handleText() {
      if (!scope.current) return;
      await animate(scope.current, { opacity: 1, scale: 1.2 });
      // Here wait for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 1500));
      if (!scope.current) return;
      await animate(scope.current, { y: -125, scale: 1 });
      await new Promise((resolve) => setTimeout(resolve, 3500));
      if (!scope.current) return;
      animate(scope.current, { y: 125, scale: 0, opacity: 0, display: 'none' });
   }
   async function handleImage() {
      if (!scopeImage.current) return;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (!scopeImage.current) return;
      await animateImage(scopeImage.current, { opacity: 1, scale: 1 });
   }

   async function handleJet() {
      if (!scopeJet.current) return;
      await new Promise((resolve) => setTimeout(resolve, 2200));
      if (!scopeJet.current) return;
      await animateJet(scopeJet.current, { opacity: 1, scale: 1.2 });
      animateJet(
         scopeJet.current,
         {
            x: [3, -2, 3], // {from, to, from
            rotate: [0, -0.5, 0.5, -0.5, 0], // {from, to, from}
         },
         {
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
            duration: 5,
         },
      );
   }

   async function handleText2() {
      if (!scopeText.current) return;
      await new Promise((resolve) => setTimeout(resolve, 6500));
      if (!scopeText.current) return;
      await animateText(scopeText.current, {
         y: -125,
         scale: 1,
         opacity: 1,
         display: 'block',
      });
   }

   useEffect(() => {
      handleText();
      handleImage();
      handleJet();
      handleText2();
   });

   return (
      <div
         className="w-screen min-h-[70vh] md:hidden flex-col p-10 relative text-black justify-center items-center flex overflow-hidden"
         ref={ref}
      >
         <motion.div
            className="text-4xl font-bold text-center"
            ref={scope}
            initial={{ opacity: 0, scale: 0.5 }}
            transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01],
            }}
         >
            Eleva tu Experiencia de Viaje
         </motion.div>
         <motion.div
            ref={scopeImage}
            className="absolute rounded-full w-9/12"
            initial={{ opacity: 0, scale: 0.5, y: 50 }}
            transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01],
            }}
         >
            <img src={mountains} className="bg-cover rounded-full" alt="" />
         </motion.div>
         <motion.img
            ref={scopeJet}
            src={jet1}
            className="absolute w-9/12"
            initial={{ opacity: 0, scale: 0.5, y: 35 }}
            transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01],
            }}
         />
         <motion.div
            className="text-4xl font-bold text-center"
            ref={scopeText}
            initial={{ opacity: 0, scale: 0.5, display: 'none' }}
            transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01],
            }}
         >
            Redefine el Lujo en los Cielos
         </motion.div>
      </div>
   );
}
