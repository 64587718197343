import React from 'react';
import  LogoTransp  from '../../components/assets/LogoTransp.png';
import { NavLink } from 'react-router-dom';
import { Globe } from 'lucide-react';
// import { GlobeAltIcon } from '@heroicons/react/24/solid';
import MenuUser from '../../components/Navbar/menu-user';
import { useAuth } from '@/components';
import DatePicker from 'react-datepicker';
import CityDropdown from './CityDropdown';
import { ArrowLeftRight } from 'lucide-react';
import { Minus } from 'lucide-react';

const NavbarMobile = ({startDate,setStartDate,endDate,setEndDate,setOrigin,setDestination,destination,origin}) => {
   const { isLoggedIn } = useAuth();
   return (
      <div className="w-full h-20 hidden px-12 bg-black justify-between md:flex py-2 items-center">
         <div className='flex gap-8'>

         <img src={LogoTransp} alt="Logo" className="size-16" />

         <div className='flex '>
                        <div className='flex flex-col my-4 w-full justify-center'>
                        
                          <div className="flex items-center justify-center p-2   my-0 ">
                            <DatePicker
                              selected={startDate}
                              dateFormat="dd MMM"
                              className='outline-none text-white bg-black text-center w-[80px]'
                              onChange={(date) => setStartDate(date)}  
                            />
                            <div>
                              <Minus size={12} className='text-white'/>
                            </div>
                            <DatePicker
                              selected={endDate}
                              dateFormat="dd MMM"
                              placeholderText='Date'
                              className='outline-none text-white bg-black text-center w-[80px]'
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>
                        </div>

                        <div className='flex flex-col my-4 border-l-2  w-full justify-center'>
                         
                          <div className="flex items-center justify-center p-2  my-0 rounded-md ">
                            <CityDropdown setSelectedCity={setOrigin} selectedCity={origin} />
                            <div>
                              <ArrowLeftRight size={18} className='text-white'/>
                            </div>
                            <CityDropdown setSelectedCity={setDestination} selectedCity={destination} />
                          </div>
                        </div>

                       
                      </div>

        
         </div>
         <div className="flex gap-6 font-medium text-base items-center justify-center">
            <div className="flex gap-8 items-center">
             

                      <NavLink
                  to="/"
                  className="aria-[current=page]:text-mobius-golden text-white"
               >
           <span className='normal-case'>Home</span> 
               </NavLink>
                 
            
           
           
              
               <Globe className='cursor-pointer' size={20} color="white" />
            </div>
            {isLoggedIn ? (
               <div className="flex gap-8 items-center bg-mobius-golden rounded-lg">
                  <MenuUser />
               </div>
            ) : (
               <div className="flex pl-4 gap-8 border-l-2 border-mobius-golden items-center">
                  <NavLink
                     to="/login"
                     className="text-white aria-[current=page]:text-mobius-golden"
                  >
                Login
                  </NavLink>
                  <NavLink
                     to="/register"
                     className="text-white peer aria-[current=page]:text-mobius-golden p-2 px-4 bg-mobius-golden rounded-lg aria-[current=page]:bg-mobius-golden/50"
                  >
                     Registrartion
                  </NavLink>
               </div>
            )}
         </div>
      </div>
   );
};

export default NavbarMobile;
