import React, { useEffect, useState } from "react";
import { ArrowLeftRight } from "lucide-react";

const PriceBreakdown = ({
  origin,
  destination,
  price,
  R_origin,
  R_destination,
  R_price,
  isRound,
  processPayment
}) => {
  const [total, setTotal] = useState('');
  const [gasFee, setGasFee] = useState('');
  const [tax, setTax] = useState('');
  const [returnTax, setReturnTax] = useState('');
  const [R_gasFee, setRGasFee] = useState('');
  const makePayment = () => {
    if (typeof processPayment === 'function') {
      processPayment(); // Call the processPayment function
    } else {
      console.error("processPayment is not a function");
    }
  };

  useEffect(() => {
    if (isRound) {
      const gas = parseFloat(((price * 5) / 100).toFixed(1));
      const returngas = parseFloat(((R_price * 5) / 100).toFixed(1));
      const d_tax = parseFloat((((price + gas) * 16) / 100).toFixed(1));
      const r_tax = parseFloat((((R_price + returngas) * 16) / 100).toFixed(1));
      const totalPrice = parseFloat(
        (gas  + d_tax  + price ).toFixed(1)
      );
      
      setGasFee(gas);
      setRGasFee(returngas);
      setTax(d_tax);
      setReturnTax(r_tax);
      setTotal(totalPrice);
    } else {
      const gas = parseFloat(((price * 5) / 100).toFixed(1));
      const d_tax = parseFloat((((price + gas) * 16) / 100).toFixed(1));
      const totalPrice = parseFloat((gas + d_tax + price).toFixed(1));
      
      setGasFee(gas);
      setTax(d_tax);
      setTotal(totalPrice);
    }
  }, [price, R_price, isRound]);

  return (
    <div>
      <div className="border-4 flex flex-col gap-[1.2rem] border-mobius-golden m-6 py-4 rounded-[20px] px-8">
        <p className="text-lg font-bold text-black">Price Breakdown</p>
        <p className="text-black font-bold"> Flights</p>
        <div className="">
        {isRound?(
          <div className="flex gap-8 justify-between">
          <p className="flex gap-2">
          {origin} <ArrowLeftRight size={18} /> {destination}
        </p>
        <p>$ {price} mxn</p>
          </div>
        ):(
          <div className="flex gap-4 justify-between">
                 <p>
          {origin}-{destination}
        </p>
        <p>$ {price} mxn</p>
          </div>
        )}
         
        </div>
      

        <p className="text-black font-bold">Gas Fee</p>
        <div className="">
        {isRound?(
          <div className="flex gap-8 justify-between">
          <p className="flex gap-2">
          {origin} <ArrowLeftRight size={18} /> {destination}
        </p>
        <p>$ {gasFee} mxn</p>
          </div>
        ):(
          <div className="flex gap-4 justify-between">
                 <p>
          {origin}-{destination}
        </p>
        <p>$ {gasFee} mxn</p>
          </div>
        )}
         
        </div>

       

        <p className="text-black font-bold">Taxes</p>
        <div className="">
        {isRound?(
          <div className="flex  justify-between">
          <p className="flex gap-2">
          {origin} <ArrowLeftRight size={18} /> {destination}
        </p>
        <p>$ {tax} mxn</p>
          </div>
        ):(
          <div className="flex gap-4 justify-between">
                 <p>
          {origin}-{destination}
        </p>
        <p>$ {tax} mxn</p>
          </div>
        )}
         
        </div>

       

        <div className="flex justify-between">
          <p className="text-black font-bold">Total</p>
          <p>$ {total} mxn</p>
        </div>
      </div>

      <button
        className="p-2 border-2 w-full border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-full"
        onClick={makePayment}
      >
        Pay
      </button>
    </div>
  );
};

export default PriceBreakdown;
