import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from '@headlessui/react';
import { X } from "lucide-react";
import axios from "axios";
import { toast } from "react-toastify";
const FilterModal = ({ closeModal, applyFilter, planesData, fleetData }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [fleetId, setFleetId] = useState('');
    const [planeId, setPlaneId] = useState('');







    const handleApplyFilter = () => {
        applyFilter({ startDate, endDate, fleetId, planeId });
        closeModal();  // Close modal after applying filter
    };





    return (
        <Dialog open={true} onClose={() => { }} static className="relative z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center overflow-y-auto justify-center p-4">
                <Dialog.Panel className="mx-auto   rounded bg-white p-6">
                    <div className="flex justify-end">
                        <X onClick={closeModal} className="self-end cursor-pointer" />
                    </div>

                    <Dialog.Title className="text-lg font-bold">Filter Earnings</Dialog.Title>

                    <div className="flex flex-col md:flex-row md:justify-between md:gap-6 gap-4">
                        <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Start Date</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="w-full px-4 max-w-[200px] py-2 border border-gray-300 rounded"
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">End Date</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="w-full px-4 max-w-[200px] py-2 border border-gray-300 rounded"
                            />
                        </div>
                    </div>

                    <div className="flex  flex-col md:flex-row  md:justify-between md:gap-6 gap-4">

                        {Array.isArray(fleetData) ? (
                            <div className="mt-4">
                                <label className="block mb-2 text-sm font-medium text-gray-700">Select Fleet</label>

                                <select
                                    name="fleet"
                                    id="fleet"
                                    placeholder="select fleet"
                                    className="rounded-lg min-h-[40px] border-2 px-5 md:w-[200px]"
                                    value={fleetId}
                                    onChange={(e) => setFleetId(e.target.value)}
                                >
                                   {/* Default value with no filters */}
                                    <option value="">All Fleets</option>

                                    {/* Dynamically generated fleet options */}
                                    {fleetData.map((fleet) => (
                                        <option key={fleet.id} value={fleet.id}>
                                            {fleet.fleetName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <div className="text-red-500">
                                Unable to load fleet data.
                            </div>)}

                        {Array.isArray(planesData) ? (
                            <div className="mt-4">
                                <label className="block mb-2 text-sm font-medium text-gray-700">Select Plane</label>
                                <select
                                    name="fleet"
                                    id="fleet"
                                    placeholder="select fleet"
                                    className=" rounded-lg min-h-[40px]  border-2 px-5 md:w-[200px] "
                                    value={planeId}
                                    onChange={(e) => setPlaneId(e.target.value)}
                                >
                                     {/* Default option for no filter */}
                                     <option value="">All Planes</option>
                                     {/* Dynamically loaded plane options */}
                                    {planesData.map((plane) => (
                                        <option key={plane.planeId} value={plane.planeId}>
                                            {plane.planereg}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <div className="text-red-500">
                                Unable to load Plane data.
                            </div>)}

                    </div>

                    <div className="mt-6 flex justify-end space-x-3">

                        <button
                            className="px-4 py-2 bg-white text-black border border-mobius-golden hover:bg-mobius-golden hover:text-white rounded"
                            onClick={handleApplyFilter}
                        >
                            Apply Filter
                        </button>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default FilterModal;
