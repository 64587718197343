import React  from 'react';
const FleetSelection = ({fleetName,handleFleetNameChange,next,handleToggle,fleetId,showSelect,setFleetId,fleetData}) => {


    return(
        <div className="h-[100vh] md:px-10 py-15 flex flex-col md:justify-evenly justify-evenly items-center">
    {!showSelect ? (
      <div>
        <div className="md:text-5xl text-4xl text-center font-bold">
          Write Your Fleet Name
        </div>
        <div className="flex flex-col gap-8 mt-16 items-center">
          <input
            type="text"
            className="border-b-2 px-2 md:w-[400px] w-[250px]  border-mobius-golden text-3xl focus:outline-none"
            value={fleetName}
            onChange={handleFleetNameChange}
          
          />
            <button
            onClick={next}
            className=" mb-8 outline outline-2 text-black hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out  mt-10 text-xl rounded-md p-3"
          >
           Create Fleet
          </button>
          <button
            onClick={handleToggle}
            className="outline outline-2 outline-mobius-golden text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-12 mt-10 text-xl rounded-xl p-2 px-3"
          >
            Already have a Fleet?
          </button>
        </div>
      </div>
    ) : (
      <div className="choose-fleet flex flex-col md:mt-8 mt-16 gap-16 items-center h-full">
        <div className="md:text-5xl text-4xl  mt-16 text-center font-bold">
          Choose Your Fleet
        </div>
        {Array.isArray(fleetData) ? (
          <select
            name="fleet"
            id="fleet"
            placeholder="select fleet"
            className="min-h-[40px] rounded-lg mt-16 border-2 px-5 md:w-[250px] w-[200px]"
            value={fleetId}
            onChange={(e) => setFleetId(e.target.value)}
          >
            {fleetData.map((fleet) => (
              <option key={fleet.id} value={fleet.id}>
                {fleet.fleetName}
              </option>
            ))}
          </select>
        ) : (
          <div className="text-red-500">
            Unable to load fleet data. You need to Register first to get access.
          </div>
        )}
        <button
          onClick={handleToggle}
          className="outline outline-2 outline-mobius-golden text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-10 mt-10 text-lg rounded-xl p-2 px-3"
        >
          Go Back
        </button>
      </div>
    )}
  </div>
    )
}

export default FleetSelection;