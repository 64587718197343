import axios from 'axios';

// Function to get the CSRF token from cookies
export function getCsrfToken() {
    let csrfToken = null;
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        let [key, value] = cookie.trim().split('=');
        if (key === 'csrftoken') {
            csrfToken = value;
            break;
        }
    }
    return csrfToken;
}

// Setting up axios defaults
axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();

// Export configured axios instance
export default axios;
