import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";

import SummaryContent from "../ClientView/SummaryContent";
import { ArrowLeft } from "lucide-react";
import { useAuth } from "@/components";

const TripDetails = () => {
  const { isLoggedIn } = useAuth();
  const routerLocation = useLocation()
  const [flightTime, setFlightTime] = useState('')
  const [returnTime, setReturnTime] = useState('')
  const { resDetails } = routerLocation.state || {};
  let navigate = useNavigate()
  useEffect(() => {
    console.log(resDetails, 'is reservation details')
  })

  const passengers = resDetails?.passengers

  const calculateTime = (departure, arrival, setTime) => {
    if (departure && arrival) {
      const [hours1, minutes1] = departure.split(":").map(Number);
      const [hours2, minutes2] = arrival.split(":").map(Number);

      const totalMinutes1 = hours1 * 60 + minutes1;
      const totalMinutes2 = hours2 * 60 + minutes2;

      const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

      const diffHours = Math.floor(differenceInMinutes / 60);
      const diffMinutes = differenceInMinutes % 60;

      const calculatedTime = `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;
      setTime(calculatedTime)

    }


  }
  const goBack = () => {
    navigate('/my-trips')
  }
  const login = () => {
    navigate('/login')
  }

  useEffect(() => {
    if (resDetails?.flights && resDetails.flights[1]) {

      console.log('if part of use effect is running')

      const departure = resDetails?.flights[1]?.departure_time.slice(0, 5)
      const arrival = resDetails?.flights[1]?.arrival_time.slice(0, 5)
      const R_departure = resDetails?.flights[0]?.departure_time.slice(0, 5)
      const R_arrival = resDetails?.flights[0]?.arrival_time.slice(0, 5)



      if (resDetails?.flights && resDetails.flights[1]) {
        calculateTime(departure, arrival, setFlightTime)
        calculateTime(R_departure, R_arrival, setReturnTime)
      }
      else {
        calculateTime(departure, arrival, setFlightTime)


      }

    }
    else if(resDetails?.flights && resDetails.flights[0]){
      console.log('2 if part of use effect is running')

      const departure = resDetails?.flights[0]?.departure_time.slice(0, 5)
      const arrival = resDetails?.flights[0]?.arrival_time.slice(0, 5)
      const R_departure = resDetails?.flights[1]?.departure_time.slice(0, 5)
      const R_arrival = resDetails?.flights[1]?.arrival_time.slice(0, 5)



      if (resDetails?.flights && resDetails.flights[1]) {
        calculateTime(departure, arrival, setFlightTime)
        calculateTime(R_departure, R_arrival, setReturnTime)
      }
      else {
        calculateTime(departure, arrival, setFlightTime)


      }

    }
    else {
      console.log('else part of use effect is running')
    }

  }, [setFlightTime, resDetails, setReturnTime])
  return (
    <div className="p-8 mt-6 px-12 md:items-start items-center md:min-h-[100vh] min-h-auto  flex md:flex-row flex-col  md:justify-between justify-center">
      <div>
        <ArrowLeft size={25} onClick={goBack} className="relative bottom-6 text-mobius-golden cursor-pointer" />
        {(resDetails?.flights && resDetails?.flights[1]) ?(
        <div className="text-2xl text-center md:text-left font-bold">
          {resDetails?.flights[1]?.origin_details.city} to {resDetails?.flights[1]?.destination_details.city} City
        </div>
        ):(
          <>
           {(resDetails?.flights && resDetails?.flights[0]) &&(
        <div className="text-2xl text-center md:text-left  font-bold">
          {resDetails?.flights[0]?.origin_details.city} to {resDetails?.flights[0]?.destination_details.city} City
        </div>
      
      )}
          </>
        )
      }


        {!isLoggedIn && (

          <div className="flex items-center mt-4 justify-between gap-8">
            <p>Sign in to access all trips</p>
            <button onClick={login} className="w-[90px] border-2 h-[35px] border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-full">
              Sign In
            </button>
          </div>
        )


        }
        <div className="font-bold  mt-8">
          Registered Passengers

          <div className="border border-mobius-golden border-2 mt-6 p-4 px-6 rounded-[15px]">
          {(resDetails?.flights && resDetails?.flights[0])? (
            <>
            {passengers.map((passenger, index) => (
              <div key={index} className="flex justify-between items-center py-4 px-2 ">
                <span className="text-lg font-medium">{passenger.first_name} {passenger.last_name}</span>
                <button className="text-mobius-golden text-sm cursor-pointer hover:underline">View Ticket</button>
              </div>
            ))}
            </>
          ):(
            <div className="text-red-700">
              No Reservation data found!
            </div>
          )
          
          }
          </div>
        </div>


      </div>
      <div className="border-4 flex flex-col    gap-[1.2rem] border-mobius-golden m-6 py-4 rounded-[20px]  px-16 ">

      {resDetails?.flights && resDetails?.flights[0] && (
       
        <div className="text-xl md:text-left text-center font-bold">   
          {passengers[0].first_name} {passengers[0].last_name}
      
        </div>
      )}

        {resDetails?.flights && resDetails?.flights[1] ? (
         <>
         
        <SummaryContent flightType='Departure'
          flightDay={resDetails.flights[1].departure_date}
          flight_time={flightTime}
          origin={resDetails?.flights[1]?.origin_details.iata_code}
          departure_time={resDetails?.flights[1]?.departure_time.slice(0, 5)}
          Airline={resDetails?.flights[1]?.airline}
          destination={resDetails?.flights[1]?.destination_details.iata_code}
          arrival_time={resDetails?.flights[1]?.arrival_time.slice(0, 5)}
        />
        <SummaryContent flightType='Return'
            flightDay={resDetails.flights[0].departure_date}
            flight_time={returnTime}
            origin={resDetails?.flights[0]?.origin_details.iata_code}
            departure_time={resDetails?.flights[0]?.departure_time.slice(0, 5)}
            Airline={resDetails?.flights[0]?.airline}
            destination={resDetails?.flights[0]?.destination_details.iata_code}
            arrival_time={resDetails?.flights[0]?.arrival_time.slice(0, 5)}
          />
        </>
        ):( 
          <>
          {resDetails?.flights && resDetails?.flights[0] && (
         <SummaryContent flightType='Departure'
         flightDay={resDetails.flights[0].departure_date}
         flight_time={flightTime}
         origin={resDetails?.flights[0]?.origin_details.iata_code}
         departure_time={resDetails?.flights[0]?.departure_time.slice(0, 5)}
         Airline={resDetails?.flights[0]?.airline}
         destination={resDetails?.flights[0]?.destination_details.iata_code}
         arrival_time={resDetails?.flights[0]?.arrival_time.slice(0, 5)}
       />
          )}
          </>
        )
      
      }
       
      </div>


    </div>
  )
}
export default TripDetails