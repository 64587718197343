export const ReservationData=[
    {
        "reservation_code": "1B4CC196",
        "created_at": "2024-09-08T04:55:08.001034Z",
        "payment_transaction": 255,
        "user": null,
        "tickets": [
            {
                "id": 1283,
                "flight": 8,
                "passenger": {
                    "id": 310,
                    "first_name": "Fahad",
                    "last_name": "Ali",
                    "email": "ali@gmail.com",
                    "phone": "77378798",
                    "birth_date": "2001-02-01",
                    "gender": "Masculino"
                },
                "price": "39.50",
                "seat_number": "8",
                "is_whole_plane": false
            }
        ],
        "passengers": [
            {
                "id": 310,
                "first_name": "fahd",
                "last_name": "a",
                "email": "ali@gmail.com",
                "phone": "77378798",
                "birth_date": "2001-02-01",
                "gender": "Masculino"
            }
        ],
        "flights": [
            {
                "id": 8,
                "flight_number": "MOB-N12333-XYZ123-15-8",
                "origin": 3,
                "destination": 1,
                "departure_date": "2024-08-15",
                "departure_time": "07:44:00",
                "arrival_date": "2024-08-15",
                "arrival_time": "17:44:00",
                "plane": 1,
                "seat_price": "39.50",
                "whole_plane_price": "7900.00",
                "crew_members": [
                    2,
                    4,
                    5
                ],
                "status": "On Time",
                "airline": "SAE",
                "origin_details": {
                    "id": 3,
                    "iata_code": "NTR",
                    "name": "Aeropuerto Internacional del Norte",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "available_seats": 186
            }
        ],
        "active": true
    },

    {
        "reservation_code": "1C4CC195",
        "created_at": "2024-09-08T04:55:08.001034Z",
        "payment_transaction": 255,
        "user": null,
        "tickets": [
            {
                "id": 1283,
                "flight": 8,
                "passenger": {
                    "id": 310,
                    "first_name": "Uzair",
                    "last_name": "Masood",
                    "email": "ali@gmail.com",
                    "phone": "77378798",
                    "birth_date": "2001-02-01",
                    "gender": "Masculino"
                },
                "price": "39.50",
                "seat_number": "9",
                "is_whole_plane": false
            }
        ],
        "passengers": [
            {
                "id": 310,
                "first_name": "Uzair",
                "last_name": "Masood",
                "email": "ali@gmail.com",
                "phone": "77378798",
                "birth_date": "2001-02-01",
                "gender": "Masculino"
            }
        ],
        "flights": [
            {
                "id": 8,
                "flight_number": "MOB-N12333-XYZ123-15-8",
                "origin": 3,
                "destination": 1,
                "departure_date": "2024-08-15",
                "departure_time": "07:44:00",
                "arrival_date": "2024-08-15",
                "arrival_time": "17:44:00",
                "plane": 1,
                "seat_price": "39.50",
                "whole_plane_price": "7900.00",
                "crew_members": [
                    2,
                    4,
                    5
                ],
                "status": "Delayed",
                "airline": "SAE",
                "origin_details": {
                    "id": 3,
                    "iata_code": "NTR",
                    "name": "Aeropuerto Internacional del Norte",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "available_seats": 186
            }
        ],
        "active": true
    },
    {
        "reservation_code": "1D4CC196",
        "created_at": "2024-09-08T04:55:08.001034Z",
        "payment_transaction": 255,
        "user": null,
        "tickets": [
            {
                "id": 1283,
                "flight": 7,
                "passenger": {
                    "id": 310,
                    "first_name": "Talha",
                    "last_name": "Hashmi",
                    "email": "ali@gmail.com",
                    "phone": "77378798",
                    "birth_date": "2001-02-01",
                    "gender": "Masculino"
                },
                "price": "39.50",
                "seat_number": "4",
                "is_whole_plane": false
            }
        ],
        "passengers": [
            {
                "id": 310,
                "first_name": "Talha ",
                "last_name": "Hashmi",
                "email": "ali@gmail.com",
                "phone": "77378798",
                "birth_date": "2001-02-01",
                "gender": "Masculino"
            }
        ],
        "flights": [
            {
                "id": 8,
                "flight_number": "MOB-N12333-XYZ123-15-8",
                "origin": 3,
                "destination": 1,
                "departure_date": "2024-08-15",
                "departure_time": "07:44:00",
                "arrival_date": "2024-08-15",
                "arrival_time": "17:44:00",
                "plane": 1,
                "seat_price": "39.50",
                "whole_plane_price": "7900.00",
                "crew_members": [
                    2,
                    4,
                    5
                ],
                "status": "On Time",
                "airline": "SAE",
                "origin_details": {
                    "id": 3,
                    "iata_code": "NTR",
                    "name": "Aeropuerto Internacional del Norte",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "available_seats": 186
            }
        ],
        "active": true
    },
    {
        "reservation_code": "4C2EC9E1",
        "created_at": "2024-09-08T09:49:19.368701Z",
        "payment_transaction": 256,
        "user": 34,
        "tickets": [
            {
                "id": 1283,
                "flight": 7,
                "passenger": {
                    "id": 310,
                    "first_name": "Talha",
                    "last_name": "Hashmi",
                    "email": "ali@gmail.com",
                    "phone": "77378798",
                    "birth_date": "2001-02-01",
                    "gender": "Masculino"
                },
                "price": "39.50",
                "seat_number": "4",
                "is_whole_plane": false
            },
            {
                "id": 1283,
                "flight": 7,
                "passenger": {
                    "id": 310,
                    "first_name": "Talha",
                    "last_name": "Hashmi",
                    "email": "ali@gmail.com",
                    "phone": "77378798",
                    "birth_date": "2001-02-01",
                    "gender": "Masculino"
                },
                "price": "39.50",
                "seat_number": "4",
                "is_whole_plane": false
            }
        ],
        "passengers": [
            {
                "id": 311,
                "first_name": "Fahad",
                "last_name": "Ali",
                "email": "fahad@gmail.com",
                "phone": "82938498",
                "birth_date": "2001-03-02",
                "gender": "Masculino"
            }
        ],
        "flights": [
            {
                "id": 2,
                "flight_number": "MOB-lli123-XYZ123-15-8",
                "origin": 1,
                "destination": 2,
                "departure_date": "2024-08-15",
                "departure_time": "10:00:00",
                "arrival_date": "2024-08-18",
                "arrival_time": "14:00:00",
                "plane": 1,
                "seat_price": "199.99",
                "whole_plane_price": "5000.00",
                "crew_members": [
                    1,
                    2,
                    3
                ],
                "status": "Delayed",
                "airline": "American Airlines",
                "origin_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 2,
                    "iata_code": "MTY",
                    "name": "Aeropuerto Internacional Mariano Escobedo",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "available_seats": 193
            },
            {
                "id": 8,
                "flight_number": "MOB-N12333-XYZ123-15-8",
                "origin": 3,
                "destination": 1,
                "departure_date": "2024-08-15",
                "departure_time": "07:44:00",
                "arrival_date": "2024-08-15",
                "arrival_time": "17:44:00",
                "plane": 1,
                "seat_price": "39.50",
                "whole_plane_price": "7900.00",
                "crew_members": [
                    2,
                    4,
                    5
                ],
                "status": "On Time",
                "airline": "SAE",
                "origin_details": {
                    "id": 3,
                    "iata_code": "NTR",
                    "name": "Aeropuerto Internacional del Norte",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "available_seats": 186
            }
        ],
        "active": true
    },
    {
        "reservation_code": "A8E217FC",
        "created_at": "2024-09-08T11:28:47.969540Z",
        "payment_transaction": 258,
        "user": 34,
        "tickets": [
            {
                "id": 1284,
                "flight": 2,
                "passenger": {
                    "id": 313,
                    "first_name": "Uzair",
                    "last_name": "Rashid",
                    "email": "fahad@gmail.com",
                    "phone": "89898",
                    "birth_date": "2001-03-02",
                    "gender": "Masculino"
                },
                "price": "199.99",
                "seat_number": "5",
                "is_whole_plane": false
            },
            {
                "id": 1285,
                "flight": 8,
                "passenger": {
                    "id": 313,
                    "first_name": "Uzair",
                    "last_name": "Rashid",
                    "email": "fahad@gmail.com",
                    "phone": "89898",
                    "birth_date": "2001-03-02",
                    "gender": "Masculino"
                },
                "price": "39.50",
                "seat_number": "9",
                "is_whole_plane": false
            }
        ],
        "passengers": [
            {
                "id": 313,
                "first_name": "Uzair",
                "last_name": "Rashid",
                "email": "fahad@gmail.com",
                "phone": "89898",
                "birth_date": "2001-03-02",
                "gender": "Masculino"
            }
        ],
        "flights": [
            {
                "id": 2,
                "flight_number": "MOB-lli123-XYZ123-15-8",
                "origin": 1,
                "destination": 2,
                "departure_date": "2024-08-15",
                "departure_time": "10:00:00",
                "arrival_date": "2024-08-18",
                "arrival_time": "14:00:00",
                "plane": 1,
                "seat_price": "199.99",
                "whole_plane_price": "5000.00",
                "crew_members": [
                    1,
                    2,
                    3
                ],
                "status": "Delayed",
                "airline": "American Airlines",
                "origin_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 2,
                    "iata_code": "MTY",
                    "name": "Aeropuerto Internacional Mariano Escobedo",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "available_seats": 192
            },
            {
                "id": 8,
                "flight_number": "MOB-N12333-XYZ123-15-8",
                "origin": 3,
                "destination": 1,
                "departure_date": "2024-08-15",
                "departure_time": "07:44:00",
                "arrival_date": "2024-08-15",
                "arrival_time": "17:44:00",
                "plane": 1,
                "seat_price": "39.50",
                "whole_plane_price": "7900.00",
                "crew_members": [
                    2,
                    4,
                    5
                ],
                "status": "On Time",
                "airline": "SAE",
                "origin_details": {
                    "id": 3,
                    "iata_code": "NTR",
                    "name": "Aeropuerto Internacional del Norte",
                    "city": "Monterrey",
                    "state_province": "Nuevo Leon",
                    "country": "Mexico"
                },
                "destination_details": {
                    "id": 1,
                    "iata_code": "TLC",
                    "name": "Aeropuero Internacional Licenciado Adolfo Lopez Mateos",
                    "city": "Toluca",
                    "state_province": "Estado de Mexico",
                    "country": "Mexico"
                },
                "available_seats": 185
            }
        ],
        "active": true
    }
]