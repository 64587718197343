import React from "react";
import { X } from "lucide-react";

const EarningModal = ({ closeModal,children, data }) => {
    return (
        <div className="fixed  inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white h-[300px] w-[350px] flex flex-col   p-6 rounded-md shadow-lg">

                <X onClick={closeModal} className="cursor-pointer self-end"/>

                <p className=" text-center text-black text-lg mb-8 font-bold">{data.heading}</p> {/* Display heading */}
               
                <div className="flex items-center gap-6">
                    <div className="w-[100px] h-[100px]">
                    {children}
                    <p className="text-center">Sales</p>
                    </div>
             
                <div>
                    <p className="text-black">{data.totalText} {data.seatNumber} </p>
                    <p className="text-black">{data.soldText}  {data.totalSoldSeats} </p> 
                </div>
                </div>
               
              
            </div>
        </div>
    );
};

export default EarningModal;
