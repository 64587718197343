import React from 'react';
import { LoginSection } from '@/components/Login';

function Login() {
   return (
      <div className='backg-login md:pt-[4rem] md:py-16 h-full md:h-auto md:mb-[0rem] mb-[8rem]   w-full  flex justify-center items-center'>
       
         <LoginSection />
         
         
      </div>
   );
}

export default Login;
