import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from 'lucide-react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import PassengerModal from './passengerModal';
import CrewModal from './CrewModal';
import StatusModal from './StatusModal';

const ActivePlane = ({
  departure,
  origin,
  availableSeats,
  arrival,
  flightID,
  destination,
 
  flightStatus,
  Date,
  planeModal,
  plane,
  crewData,
  passengerData
}) => {
  const [flightTime, setFlightTime] = useState('');
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [isPassengerModalOpen, setIsPassengerModalOpen] = useState(false);
  const [isCrewModalOpen, setIsCrewModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [status, setStatus] = useState(flightStatus);
  const [selectedStatus, setSelectedStatus] = useState(status);

  const navigate = useNavigate();


  const updateStatus = () => {
    const baseURL = '/api/fleet/owner-dashboard';
    const authToken = token;
    const flightId = flightID; // Replace with the actual flight ID you want to update
    const newStatus = selectedStatus; // New status, e.g., 'Completed', 'On Flight', 'Delayed'



    // Set up headers with the authentication token
    const headers = {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };



    // Set up the data payload with the new status
    const data = {
      status: newStatus,
    };



    // Make the request to update the flight status
    axios.patch(`${baseURL}/${flightId}/update_flight_status/`, data, { headers })
      .then(response => {
        console.log(response.status); // Should be 200
        console.log(response.data); // Confirmation of the status update
      })
      .catch(error => {
        console.error(error.response.status);
        console.error(error.response.data);
      });




  }

  useEffect(() => {
    if (departure && arrival) {
      const [hours1, minutes1] = departure.split(':').map(Number);
      const [hours2, minutes2] = arrival.split(':').map(Number);

      const totalMinutes1 = hours1 * 60 + minutes1;
      const totalMinutes2 = hours2 * 60 + minutes2;

      const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);
      const diffHours = Math.floor(differenceInMinutes / 60);
      const diffMinutes = differenceInMinutes % 60;
      const calculatedTime = `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;
      setFlightTime(calculatedTime);
    }
  }, [departure, arrival]);

  const togglePassengerModal = () => {
    setIsPassengerModalOpen(!isPassengerModalOpen);
  };
  const toggleCrewModal = () => {
    setIsCrewModalOpen(!isCrewModalOpen);
  };


  const toggleStatusModal = () => {
    setIsStatusModalOpen(!isStatusModalOpen);
  };

  const handleStatusSubmit = () => {
    updateStatus()
    setStatus(selectedStatus);
    toggleStatusModal();
  };

  const getStatusColor = () => {
    switch (status) {
      case 'On Time':
        return 'text-green-600';
      case 'On Flight':
        return 'text-blue-600';
      case 'Delayed':
        return 'text-yellow-600';
      case 'Completed':
        return 'text-gray-600';
      default:
        return 'text-black';
    }
  };

  return (
    <div className='flex mb-8 flex-col'>
      <div className='flex gap-2 flex-col'>
        <div className='text-2xl font-bold text-center'>{planeModal}-{plane} </div>
        <div className='text-center text-sm text-black'>{Date}</div>
      </div>

      <div className='flex md:flex-row flex-col items-center border-2 border-mobius-golden rounded-lg gap-6 flex-wrap md:flex-nowrap py-8 mt-4 rounded-md px-4 width-full md:justify-between justify-center'>
        <div className='text-center'>
          <p className='text-lg text-black font-bold'>{departure}</p>
          <p className='text-md text-gray-500'>{origin}</p>
        </div>

        <div className='flex relative md:top-4 top-0 gap-[4px] flex-col items-center'>
          <div className='flex md:w-[200px] w-[150px] justify-center items-center relative'>
            <div className='p-2 bg-mobius-golden rounded-full absolute left-0'></div>
            <div className='w-[250px] bg-mobius-golden rounded-lg h-1'></div>
            <div className='p-2 bg-mobius-golden rounded-full absolute right-0'></div>
          </div>
          <p className='text-gray-500 text-sm font-bold'>{flightTime}h</p>
          <div className='flex justify-between items-end'>
            <UserIcon size={20} />
            <div className='text-gray-500 text-center text-xs font-bold'>{availableSeats} seats available</div>
          </div>
        </div>

        <div className='text-center flex flex-col items-start justify-start'>
          <p className='text-lg text-black font-bold'>{arrival}</p>
          <p className='text-md text-gray-500'>{destination}</p>
        </div>

        <div className="text-center flex flex-col gap-2 items-center justify-start">
          <p className="text-md font-bold">Crew</p>
          <button onClick={toggleCrewModal} className='flex items-center hover:text-mobius-golden font-medium hover:underline justify-center'>
            View
          </button>
        </div>

        <div className='text-center flex flex-col gap-2 items-center justify-start'>
          <p className='text-md text-nowrap font-bold'>Passenger List</p>
          <button onClick={togglePassengerModal} className='flex items-center hover:text-mobius-golden hover:underline font-medium justify-center'>
            View
          </button>
        </div>

        <div className="text-center flex flex-col gap-2 items-center justify-start">
          <p className="text-md font-bold">Status</p>
          <button
            onClick={toggleStatusModal}
            className={`flex items-center text-nowrap ${getStatusColor()} hover:underline text-md font-medium justify-center`}
          >
            {status}
          </button>
        </div>
      </div>

      {/* Passenger Modal */}
   <PassengerModal
   isPassengerModalOpen={isPassengerModalOpen}
   passengerData={passengerData}
   togglePassengerModal={togglePassengerModal}

   
   />
   <CrewModal
   isCrewModalOpen={isCrewModalOpen}
   crewData={crewData}
   toggleCrewModal={toggleCrewModal}
   
   
   />
      {/* Status Modal */}
      <StatusModal
      isStatusModalOpen={isStatusModalOpen}
      toggleStatusModal={toggleStatusModal}
      setSelectedStatus={setSelectedStatus}
      selectedStatus={selectedStatus}
      handleStatusSubmit={handleStatusSubmit}
      
      />
     
    </div>
  );
};

export default ActivePlane;
