import React, { useEffect, useState } from 'react'
import { getSearchData } from '@/components/NewLandingPage/utils/storage';
import { SearchIcon } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchFlight } from '@/components/NewLandingPage';
import FlightComponent from './FlightComponent';
import loader from '../../components/assets/plane_loader.png'
const SearchFlightComp = () => {
    const searchData = getSearchData();
    const routerLocation = useLocation();
    const {returnData,isRound} = routerLocation.state || {};
    const [loading, setLoading] = useState(true);
    let [showSearch, setShowSearch] = useState(false)
   
    const handleSearchShow = () => {
        setShowSearch(!showSearch)
    }



    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        console.log('search data is', searchData)
        console.log(isRound, 'is round')

    })

    let navigate = useNavigate()
    const navigateFlights = () => {
        navigate('/book-flight')
    }

    return (
        <div>
            <div className='flex justify-between md:pt-6 pt-[6rem] items-center px-4 md:px-12 '>


                <div className=' border-2 border-mobius-golden cursor-pointer md:mx-8 mx-2  text-center w-[150px]   p-3 hover:bg-mobius-golden hover:text-white rounded-md' onClick={navigateFlights} >
                    Other Flights
                </div>
                <SearchIcon className='cursor-pointer mr-6' onClick={handleSearchShow} />
            </div>

            <div className='flex flex-col py-10 justify-center'>

                {showSearch ? (
                    <SearchFlight setShowSearch={setShowSearch} setLoading={setLoading} />
                )
                    : (

                        loading ? (
                            <div className="bg-white h-[80vh] bg-opacity-50 flex-col py-[5%] z-50 flex justify-center items-center">
                                <img src={loader} alt="Loading..." className="mt-[15%] md:w-[100px] w-[100px]" />
                                <div className="text-center mt-4 mb-[15%]">

                                    <div className="flex justify-center mt-2 space-x-2">
                                        <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse "></div>
                                        <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse animation-delay-100"></div>
                                        <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse animation-delay-200"></div>
                                        <div className="dot bg-mobius-golden w-2.5 h-2.5 rounded-full animate-pulse animation-delay-300 "></div>
                                    </div>
                                </div>
                            </div>
                        ) : (

                            <div>


                                {searchData && searchData.flightData && searchData.flightData.length > 0 ? (
                                    <>
                                        <div className='font-bold text-2xl text-center'>
                                            Available Flights
                                        </div>

                                        {searchData.flightData.map(flight => (
                                            <FlightComponent
                                                key={flight.id}
                                                flightID={flight.id}
                                                departure={flight.departure_time}
                                                origin={flight.origin_details.iata_code}
                                                availableSeats={flight.available_seats}
                                                arrival={flight.arrival_time}
                                                destination={flight.destination_details.iata_code}
                                                seat_price={flight.seat_price}
                                                plane_price={flight.whole_plane_price}
                                            />
                                        ))}


                                    </>
                                ) : (
                                    <div className='text-red-500 text-center py-12'>
                                        Cannot find any flight for provided schedule, try searching more flights.
                                    </div>
                                )}



                                {isRound ?
                                    (
                                        <div>
                                            {returnData && returnData.length > 0 ? (
                                                <>

                                                    <div className='font-bold text-2xl text-center'>
                                                        Available Flights to Return
                                                    </div>

                                                    {returnData.map(flight => (
                                                        <FlightComponent
                                                            key={flight.id}
                                                            departure={flight.departure_time}
                                                            origin={flight.origin_details.iata_code}
                                                            availableSeats={flight.available_seats}
                                                            arrival={flight.arrival_time}
                                                            destination={flight.destination_details.iata_code}
                                                            seat_price={flight.seat_price}
                                                            plane_price={flight.whole_plane_price}
                                                        />
                                                    ))}


                                                </>
                                            ) : (
                                                <div className='text-red-500 text-center py-12'>
                                                    Cannot find  any return flights for provided schedule, try searching more flights.
                                                </div>
                                            )}

                                        </div>
                                    ):(
                                        <></>
                                    )
                                }
                            </div>)
                    )}
            </div>
        </div>
    )
}

export default SearchFlightComp